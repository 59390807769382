import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getDetailLoginMember } from '../services/auth' 

export const getAuthMember = createAsyncThunk('member/getAuthMember', async () => {
  const response = await getDetailLoginMember()
  return response
})

const authMember = createSlice({
  name: 'member',
  initialState: {
    isLoading: true,
    data: null
  },
  reducers: {
    dataMember: (state, action) => {
      state.isLoading = action.payload.isLoading
      state.data = action.payload.data
    }
  },
  extraReducers: {
    [getAuthMember.pending]: (state) => {
      state.isLoading = true
    },
    [getAuthMember.fulfilled]: (state, action) => {
      state.isLoading = false
      state.data = action.payload
      
    },
    [getAuthMember.rejected]: (state) => {
      state.isLoading = false
      state.data = null
    }
  }
})

export const { dataMember } = authMember.actions
export default authMember.reducer
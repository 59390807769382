import React from 'react'
import classes from './HeaderMember.module.css'
import LajuReaksiLogo from '../../../../assets/img/LajuReaksiLight.svg'
import { Box, Burger, Flex, Group, Menu, Text, Avatar } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { update } from '../../../../store/authData'
import { useNavigate } from 'react-router-dom'
import { IconHome2, IconDoorExit } from '@tabler/icons-react'
import { getImageFile } from '../../../../services/fileImage'
import Cookies from 'universal-cookie'

const HeaderMember = ({ mobileOpened, desktopOpened, mobileToggle, desktopToggle }) => {
  const cookies = new Cookies()
  const { isLoading, data } = useSelector(state => state.member)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutUser = () => {
    cookies.remove("session")
    dispatch(update({ 'authorization': null, 'isLogin': false, 'isLoading': false }))
    navigate('/')
  }

  const mappingMemberLogin = (val) => {
    return (
      <Menu position='bottom-end' offset={3}>
        <Menu.Target>
          <Box style={{ cursor: 'pointer' }}>
            <Flex align="center">
              <Avatar variant='white' src={val.image !== null ? getImageFile(val.image) : null} radius="xl" size="md" />
            </Flex>
          </Box>
        </Menu.Target>
        <Menu.Dropdown style={{ border: '0px', padding: '0px', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
          <Box className={classes.bgHeaderDropdown} />
          <Box style={{ width: '16rem', padding: '6px' }}>
            <Box className={classes.menuMember}>
              <Avatar variant='white' src={val.image !== null ? getImageFile(val.image) : null} radius="xl" size="lg" mr={12} />
              <Text fz="sm" ml={8} mt={6} fw="bold">{val.nickname !== null ? val.nickname : val.fullName}</Text>
            </Box>
            <Box mt={70}>
              <Menu.Item leftSection={<IconHome2 size={14}/>} onClick={() => navigate('/')}>Home</Menu.Item>
              <Menu.Divider />
              <Menu.Item color='red' leftSection={<IconDoorExit size={14}/>} onClick={logoutUser}>Logout</Menu.Item>
            </Box>
          </Box>
        </Menu.Dropdown>
      </Menu>
    )
  }

  return (
    <header className={classes.root}>
      <Box className={classes.sectionHeader}>
        <Group spacing='md' align='center'>
          <Burger opened={mobileOpened} onClick={mobileToggle} hiddenFrom='sm' size='sm' />
          <Burger opened={desktopOpened} onClick={desktopToggle} visibleFrom='sm' size='md' />
          <img src={LajuReaksiLogo} width='120' alt='katalis-logo' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
        </Group>
        {!isLoading ? mappingMemberLogin(data) : ''}
      </Box>
    </header>
  )
}

export default HeaderMember
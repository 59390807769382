import React from 'react'
import { Accordion, Box, Group, Text, ActionIcon } from '@mantine/core'

const CurriculumSection = ({ curriculums }) => {

  const mappingCurriculum = (data) => data.map((val, index) => {
    const innerHtml = { __html: val.description }
    return (
      <Accordion.Item value={val.id} key={val.name} style={{ cursor: 'pointer', border: '0.125rem solid #dee2e6', borderRadius: '10px' }}>
        <Accordion.Control>
          <Group noWrap>
            <ActionIcon color='#043056' radius="xl" variant="filled">
              <Text fz={13} fw="bold">{index + 1}</Text>
            </ActionIcon>
            <Text fz="md" fw="bold">{val.name}</Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel p={10} style={{ borderTop: '1px solid #C1C2C5' }}>
          <Box py="sm">
            <Text fz={13} mt={0} dangerouslySetInnerHTML={innerHtml} />
          </Box>
        </Accordion.Panel>
      </Accordion.Item>
    )
  })
  return (
    <Box mb={35}>
      <Text fz="md" fw="bold">Materi</Text>
      <Box mt="md">
        {curriculums.length > 0 ? (<Accordion variant='separated'>{mappingCurriculum(curriculums)}</Accordion>) : (<Text fz="md" fw="bold">Event ini belum memiliki kurikulum</Text>)}
      </Box>
    </Box>
  )
}

export default CurriculumSection 
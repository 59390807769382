import { createSlice } from "@reduxjs/toolkit"

const memberProgram = createSlice({
  name: 'program',
  initialState: {
    data: null
  },
  reducers: {
    update: (state, actions) => {
      state.data = actions.payload.data
    }
  }
})

export const { update } = memberProgram.actions
export default memberProgram.reducer
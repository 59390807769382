import React, { useEffect, useState } from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import { useViewportSize } from '@mantine/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { activateAccount } from '../../services/auth'
import { Box } from '@mantine/core'
import mySwal from '../../plugins/swallAlert'
import LoadingData from '../../components/ui/LoadingData'

const VerifiedMember = () => {
  const navigate = useNavigate()
  const search = useLocation().search
  const queryParams = new URLSearchParams(search)
  const authToken = queryParams.get("token")
  const { width, height } = useViewportSize()
  const [ loading, setLoading ] = useState(true)

  const handleActivateAccount = async () => {
    setLoading(true)
    const payload = {
      token: authToken,
      verifyUrl: `lajureaksi.co.id/auth/verify`
    }
    try {
      const response = await activateAccount(payload)
      if (response) {
        mySwal.fire({
          icon: 'success',
          title: 'Aktivasi Akun Berhasil',
          text: 'Silahkan Login menggunakan akun anda',
          timer: 1500
        })
        navigate('/login')
      }
    } catch (error) {
      console.log(error)
      mySwal.fire({
        icon: 'error',
        title: 'Gagal Aktivasi Akun',
        text: 'Verifikasi telah kedaluarsa, silahkan registrasi kembali',
        timer: 1500
      })
      navigate('/')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (authToken !== null) {
      handleActivateAccount()
    }
    // eslint-disable-next-line
  }, [authToken])


  return (
    <PublicLayout>
      <Box w={width} h={height / 2}>
        {loading ? (<LoadingData/>) : ''}
      </Box>
    </PublicLayout>
    
  )
  
}

export default VerifiedMember
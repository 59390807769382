import React from 'react'
import classes from './SidebarMember.module.css'
import { Avatar, Box, ThemeIcon, Text, Divider } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { getImageFile } from '../../../../services/fileImage'
import { memberRouter } from '../../../../routes'

const SidebarMember = () => {
  const { isLoading, data } = useSelector(state => state.member)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  

  const mappingRouteDashboard = (route) => route.map((value) => {
    return (
      <a
        className={classes.link}
        key={value.name}
        href={value.route}
        data-active={value.route === pathname || undefined}
        onClick={(event) => {
          event.preventDefault()
          navigate(`${value.route}`)
        }}
      >
        <ThemeIcon variant="light" className={classes.linkIcon} data-active={value.route === pathname || undefined}  radius="md" size="md">
          <value.icon  stroke={1.5} size={18} />
        </ThemeIcon>
        <span style={{ textTransform: 'capitalize' }}>{value.name}</span>
      </a>
    )
  })

  return (
    <Box>
      <Box py='lg'>
        <Box align="center" mb={20}>
          <Avatar src={ isLoading ? null : data.image !== null ? getImageFile(data.image) : null } size={80} radius={50} mb={12} />
          <Text fw="bold">{isLoading ? '' : data.fullName}</Text>
        </Box>
        <Divider size='xs' />
      </Box>
      <Box px='md'>
        {mappingRouteDashboard(memberRouter)}
      </Box>
    </Box>
  )
}

export default SidebarMember
import React, { useState, useEffect } from 'react'
import { Avatar, Box, Center, Text, TextInput, FileInput, Divider, Input, Select, Textarea, Flex, Button } from '@mantine/core'
import { useSelector } from 'react-redux'
import { getDetailUser } from '../../../../services/user'
import { getImageFile } from '../../../../services/fileImage'
import { getProvinces, getCities } from '../../../../services/address'
import classes from './Profile.module.css'
import { IMaskInput } from 'react-imask'
import { validation } from '../../../../plugins/validation'
import { updateProfilebyUser, updateUser, updatePhotoProfile } from '../../../../services/user'
import { notificationSuccess, notificationError } from '../../../ui/Notification'
import { useDispatch } from 'react-redux'
import { getAuthMember } from  '../../../../store/authMember'

const defaultVal = {
  phone: '',
  status: 'pelajar',
  job: '',
  company: '',
  institution: '',
  department: '',
  desiredJobSector: null,
  desiredJob: null,
  linkedin: '',
  email: '',
  fullName: '',
  nickname: '',
  address: '',
  countryId: '',
  provinceId: '',
  cityId: '',
  graduationYear: null,
  entryYear: null
}

const formValidation = {
  fullName: {
    isError: false,
    message: ''
  },
  nickname: {
    isError: false,
    message: ''
  },
  linkedin: {
    isError: false,
    message: ''
  },
  desiredJob: {
    isError: false,
    message: ''
  },
  desiredJobSector: {
    isError: false,
    message: ''
  },
  department: {
    isError: false,
    message: ''
  },
  institution: {
    isError: false,
    message: ''
  },
  company: {
    isError: false,
    message: ''
  },
  job: {
    isError: false,
    message: ''
  },
  status: {
    isError: false,
    message: ''
  },
  phone: {
    isError: false,
    message: ''
  },
  address: {
    isError: false,
    message: ''
  },
  countryId: {
    isError: false,
    message: ''
  },
  provinceId: {
    isError: false,
    message: ''
  },
  cityId: {
    isError: false,
    message: ''
  },
  graduationYear: {
    isError: false,
    message: ''
  },
  entryYear: {
    isError: false,
    message: ''
  }
}

const validationUploadPhoto = {
  photo: {
    isError: false,
    message: ''
  }
}

const dataStatus = [
  {
    value: 'pelajar',
    label: 'Pelajar'
  },
  {
    value: 'bekerja',
    label: 'Bekerja'
  },
  {
    value: 'tidak_bekerja',
    label: 'Belum Bekerja'
  },
]

const FormProfile = () => {
  const dataMember = useSelector(state => state.member.data)
  const dispatch = useDispatch()
  const [ formData, setFormData ] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [ profileImage, setProfileImage ] = useState()
  const [ validationPhotoProfile, setValidationPhotoProfile ] = useState(validationUploadPhoto)
  const [ loading, setLoading ] = useState(false)
  const [ profilePhoto, setProfilePhoto ] = useState(null)
  const [ imageBase64, setImageBase64 ] = useState(null)
  const [ dataCountries, setDataCountries ] = useState([])
  const [ dataProvinces, setDataProvinces ] = useState([])
  const [ dataCities, setDataCities ] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedProvince, setSelectedProvince] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('pelajar')

  const handleProfileUser = async (userId) => {
    try {
      const response = await getDetailUser(userId)
      const dataResponse = Object.keys(response)
      if (dataResponse.length > 0) {
        const responseData = {
          fullName: response.fullName ? response.fullName : '',
          nickname: response.nickname ? response.nickname : '',
          email: response.email ? response.email : '',
          phone: response.phone ? response.phone : '',
          status: response.status ? response.status : '',
          job: response.job ? response.job : '',
          company: response.company ? response.company : '',
          institution: response.institution ? response.institution : '',
          department: response.department ? response.department : '',
          desiredJobSector: response.desiredJobSector ? response.desiredJobSector : '',
          desiredJob: response.desiredJob ? response.desiredJob : '',
          linkedin: response.linkedin ? response.linkedin : '',
          address: response.address ? response.address : '',
          countryId: response.country ? response.country.id: '',
          provinceId: response.province ? response.province.id : '',
          cityId: response.city ? response.city.id : '',
          graduationYear: response.graduationYear ? response.graduationYear : null,
          entryYear: response.entryYear ? response.entryYear : null
        }
        setFormData(responseData)
        setSelectedCountry(response.country ? response.country.id.toString(): '1')
        setSelectedProvince(response.province ? response.province.id.toString() : '')
        setSelectedCity(response.city ? response.city.id.toString() : '')
        setProfileImage(response.image)
        setSelectedStatus(response.status ? response.status : 'pelajar')
        // dispatch(dataMember({ 'data': dataMember, 'isLoading': false }))
      } else {
        const data = {
          fullName: dataMember.fullName,
          email: dataMember.email,
          phone: '',
          status: 'pelajar',
          job: '',
          company: '',
          institution: '',
          department: '',
          desiredJobSector: null,
          desiredJob: null,
          linkedin: '',
          address: '',
          countryId: '1',
          provinceId: '',
          cityId: '',
          graduationYear: null,
          entryYear: null
        }
        setFormData(data)
        setSelectedCountry('')
        setSelectedProvince('')
        setSelectedCity('')
        setSelectedStatus('pelajar')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const uploadPhotoProfile = async (userId, photoImage) => {
    const form = new FormData()
    form.append('file', photoImage)
    try {
      const response = await updatePhotoProfile(userId, form)
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  const submitUpdateProfile = async (payloadProfile) => {
    setLoading(true)
    setValidationForm(formValidation)
    const payload = {
      fullName: payloadProfile.fullName,
      phone: payloadProfile.phone,
      email: payloadProfile.email,
      linkedin: payloadProfile.linkedin,
      status: selectedStatus,
      address: payloadProfile.address,
      countryId: '1',
      provinceId: selectedProvince,
      cityId: selectedCity
    }
    if (selectedStatus === 'pelajar') {
      payload.institution = payloadProfile.institution
      payload.entryYear = payloadProfile.entryYear
      payload.department = payloadProfile.department
    } else if (selectedStatus === 'bekerja') {
      payload.job = payloadProfile.job
      payload.company = payloadProfile.company
      payload.graduationYear = payloadProfile.graduationYear
    } else if (selectedStatus === 'tidak_bekerja') {
      payload.institution = payloadProfile.institution
      payload.department = payloadProfile.department
      payload.desiredJobSector = payloadProfile.desiredJobSector
      payload.desiredJob = payloadProfile.desiredJob
      payload.graduationYear = payloadProfile.graduationYear
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    payload.countryId = Number(selectedCountry)
    payload.provinceId = Number(selectedProvince)
    payload.cityId = Number(selectedCity)
    try {
      const response = await updateProfilebyUser(dataMember.id, payload)
      if (response) {
        const payloadUser = {
          fullName: payloadProfile.fullName,
          nickname: payloadProfile.nickname
        }
        await updateUser(dataMember.id, payloadUser)
        if (profilePhoto !== null) {
          await uploadPhotoProfile(dataMember.id, profilePhoto)
        }
        setLoading(false)
        notificationSuccess('Update Profile Berhasil', 'Profile anda sudah diupdate')
        handleProfileUser(dataMember.id)
        dispatch(getAuthMember())
      }
    } catch (error) {
      setLoading(false)
      const errorMessage = error.response.data.message
      notificationError('Update Profile Gagal', 'Silahkan periksa kembali form anda')
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleGetCountries = () => {
    const dataCountry = [
      {
        value: '1',
        label: 'INDONESIA'
      }
    ]
    setDataCountries(dataCountry)
    setSelectedCountry(dataCountry[0].value)
  }

  const handleGetProvinces = async () => {
    try {
      const response = await getProvinces()
      const provinces = response.data
      const mappingProvinces = provinces.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setDataProvinces(mappingProvinces)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetCities = async (provinceId) => {
    const params = {
      province: provinceId
    }
    try {
      const response = await getCities(params)
      const cities = response.data
      const mappingCities = cities.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setDataCities(mappingCities)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (dataMember) {
      handleProfileUser(dataMember.id)
    }
    handleGetCountries()
    //eslint-disable-next-line
  }, [dataMember])

  useEffect(() => {
    if (formData.countryId !== null || formData.countryId !== undefined || selectedCountry !== null) {
      handleGetProvinces()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (formData.provinceId !== null || formData.provinceId !== undefined || selectedProvince !== null) {
      handleGetCities(formData.provinceId)
    }
    //eslint-disable-next-line
  }, [])

  const handleChangeForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleAvatarProfile = (fileUpload) => {
    setValidationPhotoProfile(validationUploadPhoto)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationPhotoProfile({
        photo: {
          isError: true,
          message: 'File foto terlalu besar'
        }
      })
      setProfilePhoto(null)
      return
    }
    uploadAvatar(fileUpload)
    setProfilePhoto(fileUpload)
  }

  const uploadAvatar = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBase64(reader.target.result)
    }
  }

  const mappingStatusForm = (status) => {
    if (status === 'pelajar') {
      return (
        <Box>
          <TextInput
            name="institution"
            value={formData.institution}
            placeholder="Isi Universitas / Sekolah"
            label="Universitas / Sekolah"
            error={validationForm.institution.isError ? `${validationForm.institution.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="entryYear"
            value={formData.entryYear}
            placeholder="isi tahun masuk"
            label="Tahun Masuk"
            type='number'
            error={validationForm.entryYear.isError ? `${validationForm.entryYear.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="department"
            value={formData.department}
            placeholder="Isi Jurusan"
            label="Jurusan"
            error={validationForm.department.isError ? `${validationForm.department.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
        </Box>
      )
    } else if (status === 'bekerja') {
      return (
        <Box>
          <TextInput
            name="graduationYear"
            value={formData.graduationYear}
            placeholder="isi lulus pendidikan"
            label="Tahun Lulus"
            type='number'
            error={validationForm.graduationYear.isError ? `${validationForm.graduationYear.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="job"
            value={formData.job}
            placeholder="Isi Pekerjaan"
            label="Pekerjaan"
            error={validationForm.job.isError ? `${validationForm.job.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="company"
            value={formData.company}
            placeholder="Isi Perusahaan"
            label="Perusahaan"
            error={validationForm.company.isError ? `${validationForm.company.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
        </Box>
      )
    } else if (status === 'tidak_bekerja') {
      return (
        <Box>
          <TextInput
            name="institution"
            value={formData.institution}
            placeholder="Isi Universitas / Sekolah"
            label="Universitas / Sekolah"
            error={validationForm.institution.isError ? `${validationForm.institution.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="department"
            value={formData.department}
            placeholder="Isi Jurusan"
            label="Jurusan"
            error={validationForm.department.isError ? `${validationForm.department.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="graduationYear"
            value={formData.graduationYear}
            placeholder="isi lulus pendidikan"
            label="Tahun Lulus"
            type='number'
            error={validationForm.graduationYear.isError ? `${validationForm.graduationYear.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="desiredJobSector"
            value={formData.desiredJobSector}
            placeholder="Isi Pekerjaan yang diinginkan"
            label="Sektor Pekerjaan yang diinginkan"
            error={validationForm.desiredJobSector.isError ? `${validationForm.desiredJobSector.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
          <TextInput
            name="desiredJob"
            value={formData.desiredJob}
            placeholder="Isi Pekerjaan yang diinginkan"
            label="Pekerjaan yang diinginkan"
            error={validationForm.desiredJob.isError ? `${validationForm.desiredJob.message}` : ''}
            onChange={handleChangeForm}
            withAsterisk
            mb={10}
          />
        </Box>
      )
    }
  }


  return (
    <Box>
      <Box>
        <Text py={12} ta='center' fw='bold' fz={14}>My Avatar Profile</Text>
        <Center h={200}>
          <Avatar radius={100} size={150} src={imageBase64 || getImageFile(profileImage)} style={{ backgroundColor: '#ffff' }} />
          <FileInput className={classes.formAvatar} size={150} onChange={handleAvatarProfile} accept="image/png,image/jpeg" />
        </Center>
        <Text ta="center" c="red" fz={12}>{validationPhotoProfile.photo.message}</Text>
        <Text mb={8} ta="center" c="yellow" fz={12} fw="bold">Klik Avatar untuk upload gambar</Text>
        <Box>
          <Text ta="center" c="gray.5" fz={12}>Ukuran gambar maksimal 2 mb</Text>
          <Text ta="center" c="gray.5" fz={12}>Tipe file yang diperbolehkan: jpg, png, jpeg</Text>
        </Box>
      </Box>
      <Divider size="xs" my="md" />
      <Box mb="md">
        <TextInput
          name="fullName"
          value={formData.fullName}
          placeholder="Isi Nama Lengkap"
          label="Nama Lengkap"
          error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ''}
          onChange={handleChangeForm}
          withAsterisk
          mb={10}
        />
        <Input.Wrapper 
          label="Nomor Hp"
          error={validationForm.phone.isError ? `${validationForm.phone.message}` : ''}
          withAsterisk
          mb={10}
        >
          <Input
            name="phone"
            value={formData.phone}
            placeholder="Isi Nomor HP"
            component={IMaskInput}
            mask="+6200000000000"
            onChange={handleChangeForm}
          />
        </Input.Wrapper>
        <TextInput
          name="email"
          value={formData.email}
          label="Email"
          disabled
          mb={10}
        />
        <Select
          disabled
          name="countryId"
          label="Negara"
          placeholder="Pilih Negaramu"
          searchable
          nothingFound="Tidak Ada Negara"
          data={dataCountries}
          mb={10}
          error={validationForm.countryId.isError ? `${validationForm.countryId.message}` : ''}
          value={selectedCountry}
          withAsterisk
        />
        <Select
          disabled={formData.countryId === null || formData.countryId === undefined}
          name="provinceId"
          label="Provinsi"
          placeholder="Pilih Provinsimu"
          searchable
          nothingFound="Tidak Ada Provinsi"
          data={dataProvinces}
          error={validationForm.provinceId.isError ? `${validationForm.provinceId.message}` : ''}
          mb={10}
          onChange={(e) => [setSelectedProvince(e), handleGetCities(Number(e), setSelectedCity(null))]}
          value={selectedProvince}
          withAsterisk
          allowDeselect
        />
        <Select
          disabled={formData.provinceId === null || formData.provinceId === undefined}
          name="cityId"
          label="Kota"
          placeholder="Pilih Kotamu"
          searchable
          nothingFound="Tidak Ada Kota"
          data={dataCities}
          error={validationForm.cityId.isError ? `${validationForm.cityId.message}` : ''}
          mb={10}
          onChange={setSelectedCity}
          value={selectedCity}
          withAsterisk
        />
        <Textarea
          name="address"
          label="Alamat"
          value={formData.address}
          placeholder="Isi alamatmu"
          error={validationForm.address.isError ? `${validationForm.address.message}` : ''}
          onChange={handleChangeForm}
          withAsterisk
          mb={10}
        />
        <Input.Wrapper
          label="URL LinkedIn"
          error={validationForm.linkedin.isError ? `${validationForm.linkedin.message}` : ''}
          withAsterisk
          mb={10}
        >
          <Input
            name="linkedin"
            value={formData.linkedin}
            placeholder="Isi URL linked in"
            leftSection={<Text fz={13} c='gray.7' py={7} px={12} bg="gray.1">https://www.</Text>}
            leftSectionWidth={110}
            onChange={handleChangeForm}
          />
        </Input.Wrapper>
        <Select
          name="status"
          placeholder="pilih status pekerjaan"
          value={selectedStatus}
          data={dataStatus}
          label="Status Pekerjaan"
          onChange={(e) => setSelectedStatus(e)}
          mb={10}
        />
        {mappingStatusForm(selectedStatus)}
        <Box mt={20}>
          <Flex justify="flex-end">
            <Button loading={loading} variant="filled" color='red' onClick={() => submitUpdateProfile(formData)}>Update Profile</Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default FormProfile
import React, { useEffect, useState } from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import { getProgramList } from '../../services/program'
import { Box, Button, Container, Grid, Select, Text, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import classes from './Training.module.css'
import SkeletonCard from '../../components/ui/SekeletonCard'
import ProgramCard from '../../components/pages/Program/ProgramCard'
import dayjs from 'dayjs'

const defaultParameter = {
  skip: 0,
  take: 8,
  search: '',
  fromDate: '',
  toDate: '',
  order: 'desc',
  kind: 'training'
}

const defaultOrder = [
  {
    label: 'Terbaru',
    value: 'desc'
  },
  {
    label: 'Terlama',
    value: 'asc'
  }
]

const Training = () => {
  const [params, setParams] = useState(defaultParameter)
  const [trainingList, setTrainingList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleGetTrainingList = async () => {
    setLoading(true)
    try {
      const response = await getProgramList(params)
      setTrainingList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetTrainingList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value })
  }

  const handleChangeOrder = (e) => {
    setParams({ ...params, 'order': e })
  }

  const handleChangeDate = (e) => {
    if (e[1] !== null) {
      setParams({ ...params, 'fromDate': dayjs(e[0]).format('YYYY-MM-DD'), 'toDate': dayjs(e[1]).format('YYYY-MM-DD') })
    } else {
      setParams({ ...params, 'fromDate': '', 'toDate': '' })
    }
  }

  const handlePage = (params) => {
    if (trainingList.length < count) {
      setParams({ ...params, 'take': params.take + 8 })
    }
  }

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Grid.Col span={{base: 6, md: 6, lg: 3 }} key={i}>
        <SkeletonCard />
      </Grid.Col>
    )
  })

  const mappingDataTraining = (data) => {
    if (data.length > 0) {
      const remap = data.map((val, index) => {
        return (
          <Grid.Col span={{base: 6, md: 6, lg: 3 }} key={index}>
            <ProgramCard data={val} />
          </Grid.Col>
        )
      })
      return remap
    } else {
      return (
        <Grid.Col span={12}>
          <Box ta='center' py={60}>
            <Text c="dark">Training yang anda cari belum tersedia</Text>
          </Box>
        </Grid.Col>
      )
    }
  }

  return (
    <PublicLayout>
      <Box className={classes.trainingSection}>
        <Container fluid className={classes.section}>
          <Box>
            <Box className={classes.paddingPage}>
              <Text className={classes.titleHeader} fw="bold">Temukan Pelatihan yang ingin kamu hadiri!</Text>
              <Text className={classes.captionHeader}>Program pelatihan dan webinar dari PT Katalis Talenta Indonesia</Text>
              <Box className={classes.searchTrainingBox}>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                    <TextInput
                      placeholder="Cari Training. . ."
                      name="search"
                      onChange={handleSearchChange}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                    <Select
                      name="order"
                      data={defaultOrder}
                      onChange={handleChangeOrder}
                      allowDeselect={false}
                      value={params.order}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                    <DatePickerInput
                      clearable
                      valueFormat="DD-MM-YYYY"
                      type="range"
                      placeholder="Pilih Tanggal Training"
                      onChange={handleChangeDate}
                      miw={220}
                    />
                  </Grid.Col>
                </Grid>
              </Box>
            </Box>
            <Box mt="xl">
              <Grid gutter='xl' className={classes.gridProgramCard} align="center">
                {loading ? loadingData(8) : mappingDataTraining(trainingList)}
              </Grid>
            </Box>
            <Box mt={30} align="center">
              {trainingList.length < count ? (<Button loading={loading} color='red' onClick={() => handlePage(params)}>Selengkapnya</Button>) : ''}
            </Box>
          </Box>
        </Container>
      </Box>
    </PublicLayout>
    
  )
}

export default Training
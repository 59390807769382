import React from 'react'
import { useViewportSize } from '@mantine/hooks'
import { Box, Center, Loader } from '@mantine/core'

const LoadingData = () => {
  const { width, height } = useViewportSize()
  return (
    <Box pos="relative">
      <Center maw={width} h={height} mx="auto">
        <Loader size="lg" />
      </Center>
    </Box>
  )
}

export default LoadingData
import http from "../plugins/axios"

export const getProgramList = async (params) => {
  try {
    const response = await http.get(`/events`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getProgrambySlug = async (slug) => {
  try {
    const response = await http.get(`/events/${slug}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getProgramSchedulebyId = async (scheduleId) => {
  try {
    const response = await http.get(`/event-schedules/${scheduleId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getRecomendProgram = async (params) => {
  try {
    const response = await http.get(`/events/recommendations`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}
import http from "../plugins/axios"

export const register = async (payload) => {
  try {
    const response = await http.post(`/auth/register`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const login = async (payload) => {
  try {
    const response = await http.post(`/auth/login`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const refreshToken = async (payload) => {
  try {
    const response = await http.post(`/auth/refresh`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const resetPasswordRequest = async (payload) => {
  try {
    const response = await http.post(`/password-resets/request`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const resetPasswordVerify = async (resetToken, payload) => {
  try {
    const response = await http.post(`/password-resets/verify?token=${resetToken}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}
export const activateAccount = async (payload) => {
  try {
    const response = await http.post(`/auth/activate`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUserPassword = async (userId, payload) => {
  try {
    const response = await http.post(`/users/${userId}/password`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailLoginMember = async () => {
  try {
    const response = await http.get(`/auth`)
    return response.data
  } catch(error) {
    throw error
  }
}
import { createSlice } from "@reduxjs/toolkit"

const cartData = createSlice({
  name: 'checkout',
  initialState: {
    cart: null,
  },
  reducers: {
    update: ( state, action ) => {
      state.cart = action.payload.cart
    }
  }
})

export const { update } = cartData.actions
export default cartData.reducer
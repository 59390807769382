import React, { useState } from 'react'
import { Accordion, Box, Divider, Group, Text, TextInput, Button, Flex } from '@mantine/core'
import { IconTicket } from '@tabler/icons-react'
import { validateCodeVoucher } from '../../../services/transaction'
import { notificationSuccess, notificationError } from '../../ui/Notification'
import { useSelector, useDispatch } from 'react-redux'
import { update } from '../../../store/cartData'
import classes from '../../../pages/Checkout/Checkout.module.css'
import { formatPrice } from '../../../plugins/formatPrice'

const defaultDataVoucher = {
  amount: 0,
  type: '',
  code: '',
  name: ''
}

const VoucherSession = () => {
  const cartItems = useSelector(state => state.checkout.cart)
  const dispatch = useDispatch()
  const [ voucher, setVoucher ] = useState('')
  const [stateVoucher, setStateVoucher] = useState(false)
  const [defaultPrice, setDefaultPrice] = useState(0)
  const [resDataVoucher, setResDataVoucher] = useState(defaultDataVoucher)
  const [loadingVoucher, setLoadingVoucher] = useState(false)

  const handleChangeForm = (e) => {
    setVoucher(e.target.value)
  }

  const handleValidateVoucher = async () => {
    setLoadingVoucher(true)
    if (voucher === '') {
      setLoadingVoucher(false)
      return
    }
    try {
      const response = await validateCodeVoucher(voucher, cartItems.eventScheduleIds)
      if (response) {
        setStateVoucher(true)
        setDefaultPrice(cartItems.totalPrice)
        const dataVoucher = response
        setResDataVoucher(dataVoucher)
        let totalDiscount = 0
        notificationSuccess('Kode Voucher Berhasil Dipakai', 'Kode voucher anda telah tervalidasi')
        const data = {
          transactionMethodId: cartItems.transactionMethodId,
          eventScheduleIds: cartItems.eventScheduleIds,
          eventSlug: cartItems.eventSlug,
          totalPrice: null, 
          discountCode: voucher,
          previousPrice: cartItems.totalPrice,
          totalVoucher: dataVoucher.amount,
          typeVoucher: dataVoucher.type
        }
        if (dataVoucher.type === 'total') {
          totalDiscount = cartItems.totalPrice - dataVoucher.amount
        } else {
          totalDiscount = cartItems.totalPrice - (cartItems.totalPrice * dataVoucher.amount / 100)
        }
        data.totalPrice = totalDiscount < 0 ? 0 : totalDiscount
        dispatch(update({ 'cart': data }))
      }
    } catch (error) {
      console.log(error)
      notificationError('Kode Voucher Gagal Dipakai', 'Kode Voucher anda sudah tidak lagi valid')
    } finally {
      setLoadingVoucher(false)
    }
  }

  const detachVoucher = () => {
    setStateVoucher(false)
    setResDataVoucher(defaultDataVoucher)
    setVoucher('')
    const data = {
      transactionMethodId: cartItems.transactionMethodId,
      eventScheduleIds: cartItems.eventScheduleIds,
      eventSlug: cartItems.eventSlug,
      totalPrice: defaultPrice,
    }
    dispatch(update({ 'cart': data }))
  }

  const voucherForm = () => {
    return (
      <Box>
        <Box mt={20}>
          <TextInput
            name="voucher"
            value={voucher}
            label="Kode Promo"
            placeholder="Masukkan kode promo"
            onChange={handleChangeForm}
            mb={20}
          />
        </Box>
        <Flex justify="end">
          <Button loading={loadingVoucher} onClick={() => handleValidateVoucher()}>Pakai</Button>
        </Flex>
      </Box>
    )
  }

  const activatedVoucher = () => {
    return (
      <Box>
        <Box mt={20} mb={20}>
          <Text fz={14}>Selamat! Kamu Telah Hemat <Text component="span" color="red" fw="bold">{resDataVoucher.type === 'percentage' ? `${resDataVoucher.amount}%` : formatPrice(resDataVoucher.amount)}</Text><Text component="span"> Dengan Kode Promo</Text></Text>
          <Text fw="bold">"{resDataVoucher.code}"</Text>
        </Box>
        <Flex justify="end">
          <Button color="red" onClick={() => detachVoucher()}>Hapus Kode Voucher</Button>
        </Flex>
      </Box>
    )
  }

  return (
    <Box mb="xl">
      <Accordion variant="contained" defaultValue="codeVoucher">
        <Accordion.Item value="codeVoucher">
          <Accordion.Control>
            <Group noWrap>
              <IconTicket color="blue" size="2.5rem" />
              <Box>
                <Text className={classes.titleSection} fw="bold">Kode Voucher</Text>
                <Text className={classes.captionSection} c="gray.5" weight={400}>Masukkan kode promo yang anda miliki</Text>
              </Box>
            </Group>
          </Accordion.Control>
          <Accordion.Panel p={8}>
            <Divider mb="sm" />
            {stateVoucher ? activatedVoucher() : voucherForm()}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}

export default VoucherSession
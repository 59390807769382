import React, { useEffect, useState } from 'react'
import { getProgrambySlug } from '../../../services/program'
import { useParams } from 'react-router-dom'
import { LoadingOverlay, Box } from '@mantine/core'
import DetailSlug from '../../../components/pages/Program/details/DetailSlug'

const defaultVal = {
  name: '',
  description: '',
  type: '',
  eventSchedules: [],
  curriculums: [],
  eventImages: [],
  tutors: []
}

const BootcampSlug = () => {
  const params = useParams()
  const slug = params.slug
  const [detailProgram, setDetailProgram] = useState(defaultVal)
  const [loading, setLoading] = useState(true)

  const handleGetDetailProgram = async () => {
    setLoading(true)
    try {
      const response = await getProgrambySlug(slug)
      setDetailProgram(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetDetailProgram()
    // eslint-disable-next-line
  }, [])


  return (
    <Box pos='relative'>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <DetailSlug detail={detailProgram} loading={loading} />
    </Box>
  )
}

export default BootcampSlug
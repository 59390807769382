import React, { useEffect, useState } from 'react'
import MemberLayout from '../../../../layouts/MemberLayout'
import { Accordion, Badge, Box, Card, Center, Divider, Flex, Image, Loader, Table, Text, Title, Button} from '@mantine/core'
import { useParams } from 'react-router-dom'
import { getDetailTransactionbyInvoice } from '../../../../services/transaction'
import { getImageFile } from '../../../../services/fileImage'
import { formatPrice } from '../../../../plugins/formatPrice'
import CopyText from '../../../../components/ui/CopyText'
import { paymentTutorial } from '../../../../plugins/paymentTutorial'
import dayjs from 'dayjs'
import logoLajuReaksi from '../../../../assets/img/LajuReaksiLight.svg'
import classes from '../../MemberPage.module.css'
import CountdownTimer from '../../../../components/ui/CountdownTimer'
import { modalConfirmTransaction } from '../../../../components/ui/ModalManager/modalConfirmTransaction'
import { cancelTransaction } from '../../../../services/transaction'
import { useNavigate } from 'react-router-dom'

const transactionMethod = {
  'actions': 'QR Code',
  'va_numbers': 'Virtual Number',
  'payment_code': 'Payment Code',
  'bill_key': 'Account Number'
}

const badgeStatus = {
  pending: {
    label: 'Menunggu Pembayaran',
    color: 'yellow'
  },
  fail: {
    label: 'Gagal',
    color: 'red'
  },
  success: {
    label: 'Transaksi Berhasil',
    color: 'green'
  }
}

const DetailTransaction = () => {
  const navigate = useNavigate()
  const params = useParams()
  const invoiceId = params.invoice
  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState(null)

  const handleGetDetailTransaction = async () => {
    setLoading(true)
    try {
      const response = await getDetailTransactionbyInvoice(invoiceId)
      setDetail(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetDetailTransaction()
    // eslint-disable-next-line
  }, [])

  const confirmAbortTransaction = () => {
    modalConfirmTransaction('Batalkan Transaksi', detail.id, detail.invoiceId, cancelTransaction, () => navigate('/transaction'))
  }

  const virtualData = (dataVirtual) => {
    let dataVirtualAccount = ''
    const data = dataVirtual
    const methodKey = Object.keys(data)
    if (methodKey[0] === 'va_numbers') {
      dataVirtualAccount = data[methodKey][0].va_number
    } else if (methodKey[0] === 'payment_code') {
      dataVirtualAccount = data[methodKey]
    } else if (methodKey[0] === 'actions') {
      dataVirtualAccount = data[methodKey][0].url
    } else if (methodKey[0] === 'bill_key') {
      dataVirtualAccount = data.bill_key
    }
    return dataVirtualAccount
  }

  const mappingDataTransaction = (dataTransaction, amountPayment) => dataTransaction.map((value, index) => {
    return (
      <Table.Tr key={value.eventSchedule.name}>
        <Table.Td>{index + 1}</Table.Td>
        <Table.Td>{value.eventSchedule.event.name}</Table.Td>
        <Table.Td>{value.eventSchedule.name}</Table.Td>
        <Table.Td>{formatPrice(amountPayment)}</Table.Td>
      </Table.Tr>
    )
  })

  const mappingTutorial = (tutorialBank) => {
    const tutorialMethod = paymentTutorial[tutorialBank]
    if (!tutorialMethod) {
      return (
        <Text fz="xs">Belum ada tutorialnya</Text>
      )
    }
    const mappingTitle = Object.keys(tutorialMethod).map((val, index) => {
      return (
        <Accordion.Item value={val} key={index} style={{ cursor: 'pointer' }}>
          <Card padding='xs' radius='xs' withBorder>
            <Accordion.Control>
              <Text fz="xs" fw="bold"> {val}</Text>
            </Accordion.Control>
          </Card>
          <Accordion.Panel>
            <Box p={10}>
              {mappingDescription(val, tutorialBank)}
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
      )
    })
    return mappingTitle
  }

  const mappingPayment = (typePayment, paymentCode) => {
    if (typePayment !== 'QR Code') {
      return (
        <Flex align="center">
          <Text fz="sm" fw="bold" mr={8}>{virtualData(paymentCode)}</Text>
          <CopyText text={virtualData(paymentCode)} />
        </Flex>
      )
    }
  }

  const mappingDescription = (tutorial, bankName) => {
    const tutorialMethod = paymentTutorial[bankName]
    const tutorialDescription = tutorialMethod[tutorial].map((val, index) => {
      return (
        <Text fz="xs">{index + 1} {val}</Text>
      )
    })
    return tutorialDescription
  }

  const paymentMethod = (detail) => {
    if (detail.transactionMethod !== null) {
      return (
        <Box mt={16}>
          <Flex align="center">
            <Image src={getImageFile(detail.transactionMethod.image)} w={50} alt={detail.transactionMethod.name} />
            <Box ml={20}>
              <Text fz="sm" fw="bold">{detail.transactionMethod.name}</Text>
              <Text fz="sm" c='gray.6'>{formatPrice(detail.amount)}</Text>
            </Box>
          </Flex>
        </Box>
      )
    }
  }

  const actionPayment = (detail) => {
    if (detail.paymentActions !== null) {
      if (transactionMethod[Object.keys(detail.paymentActions)[0]] !== 'QR Code') {
        return (
        <Box mt={12}>
          <Text fz="sm">{transactionMethod[Object.keys(detail.paymentActions)[0]]}:</Text>
          {mappingPayment(transactionMethod[Object.keys(detail.paymentActions)[0]], detail.paymentActions)}
        </Box>
      )
      }
    }
  }

  const showQRImage = (detail) => {
    if (detail.paymentActions !== null) {
      if (transactionMethod[Object.keys(detail.paymentActions)[0]] === 'QR Code') {
        const dataQRImage = detail.paymentActions.actions[0]
        return (
        <Box my={14}>
          <Flex justify='center' >
            <Image src={dataQRImage.url} w={200} />
          </Flex>
          <Text fz="xs" fw={600} ta='center'>Silahkan scan barcode untuk menyelesaikan transaksimu</Text>
        </Box>
        
      )
      }
    }
  }

  const mappingDuration = (detail) => {
    if (detail.status === 'pending') {
      return (
        <Box mt={12}>
          <Text fz="sm">Durasi Kedaluarsa:</Text>
          <CountdownTimer targetDate={detail.expireDate} fz={13} fw='bold' sizeBadge='xs' />
        </Box>
      )
    }
  }

  const tutorialPayment = (detail) => {
    if (detail.transactionMethod !== null) {
      return (
        <Box>
          <Divider my="md" />
          <Box>
            <Text fw="bold">Cara Pembayaran:</Text>
            <Box mt={14}>
              <Accordion variant="separated" chevronPosition="right" mx="auto">
                {mappingTutorial(detail.transactionMethod.name)}
              </Accordion>
            </Box>
          </Box>
          <Divider my="md" />
        </Box>
      )
    }
  }

  const MappingDataDetail = ({ detailInvoice }) => {
    return (
      <Card shadow="xs" padding='md' radius='sm' withBorder key={detailInvoice.invoiceId}>
        <Box mx={7}>
          <Box align="left">
            <Image src={logoLajuReaksi} w={110} alt='logo-laju-reaksi' />
          </Box>
          <Divider my="md" />
          <Box>
            <Box align="left">
              <Text fw="bold">Ditagih ke:</Text>
            </Box>
            <Box align="right">
              <Text fw="bold">{detailInvoice.user.name}</Text>
              <Text>{detailInvoice.user.email}</Text>
              <Text>{detailInvoice.user.phone}</Text>
            </Box>
          </Box>
          <Divider my="md" />
          <Box>
            <Box align="left">
              <Text fw="bold">Pembayaran:</Text>
              {paymentMethod(detailInvoice)}
              {actionPayment(detailInvoice)}
              <Box mt={12}>
                <Text fz="sm">Nominal:</Text>
                <Text fz="sm" fw="bold" >{formatPrice(detailInvoice.amount)}</Text>
              </Box>
              <Box mt={12}>
                <Text fz="sm">Invoice:</Text>
                <Text fz="sm" fw="bold" >{detailInvoice.invoiceId}</Text>
              </Box>
              <Box mt={12}>
                <Text fz="sm">Tanggal Kedaluarsa:</Text>
                <Text fz="sm" fw="bold">{dayjs(detailInvoice.expireDate).format("DD-MM-YYYY HH:MM")}</Text>
              </Box>
              {mappingDuration(detailInvoice)}
              <Box mt={12}>
                <Text fz="sm">Status Pembayaran:</Text>
                <Badge size="xs" radius="sm" variant="filled" color={badgeStatus[detailInvoice.status].color}>{badgeStatus[detailInvoice.status].label}</Badge>
              </Box>
            </Box>
          </Box>
          {showQRImage(detailInvoice)}
          <Divider my="md" />
          <Box>
            <Text fw="bold">Transaksi Pembelian:</Text>
            <Table fontSize="xs" horizontalSpacing="sm" verticalSpacing="xs" mt={14} highlightOnHover withTableBorder>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>No</Table.Th>
                  <Table.Th>Nama Event</Table.Th>
                  <Table.Th>Nama Schedule</Table.Th>
                  <Table.Th>Harga</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{mappingDataTransaction(detailInvoice.userEvents, detailInvoice.amount)}</Table.Tbody>
            </Table>
          </Box>
          <Divider my="md" />
          <Box>
            <Flex justify="space-between" align="center">
              <Text fz="sm" fw="bold">Total Tagihan:</Text>
              <Text fz="sm" fw="bold" c="blue.6">{formatPrice(detailInvoice.amount)}</Text>
            </Flex>
          </Box>
          {tutorialPayment(detailInvoice)}
          <Flex justify='end'>
            <Button onClick={confirmAbortTransaction} size='xs' variant="filled" color="red">Batalkan Transaksi</Button>
          </Flex>
        </Box>
      </Card>
    )
  }

  const LoadingInvoice = () => {
    return (
      <Center h={400} mx="auto">
        <Loader size="xl" />
      </Center>
    )
  }

  return (
    <MemberLayout>
      <Box mt={12}>
        <Box align="center">
          <Title className={classes.titleHeader}>Detail Transaksi</Title>
        </Box>
        <Box mt={30}>
          {!loading ? (<MappingDataDetail detailInvoice={detail} />) : (<LoadingInvoice />)}
        </Box>
      </Box>
    </MemberLayout>
    
  )
}

export default DetailTransaction
import http from "../plugins/axios"

// blogs
export const getBlogs = async (params) => {
  try {
    const response = await http.get(`/blogs`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

// get Detail Blog
export const getDetailBlog = async (slug) => {
  try {
    const response = await http.get(`/blogs/${slug}`)
    return response.data
  } catch(error) {
    throw error
  }
}
import http from "../plugins/axios"

export const createAnswerAttempts = async (payload) => {
  try {
    const response = await http.post(`/answer-attempts`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}
export const generateAnswerAttemptsScore = async (answerAttemptId) => {
  try {
    const response = await http.post(`/answer-attempts/${answerAttemptId}/submit`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getOneAnswerAttempts = async (contentId, answerAttemptId) => {
  try {
    const response = await http.get(`/participant/user-events/contents/${contentId}/attempts/${answerAttemptId}`)
    return response.data
  } catch(error) {
    throw error
  }
}


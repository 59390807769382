import React from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import classes from './About.module.css'
import { Box, Container, Grid, Image, Text, Paper, Flex, ActionIcon } from '@mantine/core'
import LajuReaksiLogo from '../../assets/img/LajuReaksiLight.svg'
import KatalisLogo from '../../assets/img/katalisLogoFull.png'
import ConsultantSection from '../../components/pages/Home/ConsultantSection'
import { useNavigate } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import { IconPhone, IconMail,IconMapPin } from '@tabler/icons-react'

const About = () => {
  const navigate = useNavigate()
  const { height, width } = useViewportSize()

  const defaultCaption = {
    text: 'Terdapat artikel menarik dan kegiatan yang telah dilaksanakan untuk mendampingi perusahaan yang menggunakan jasa kami.',
    button: 'Menuju Blog'
  }

  const handleRoute = () => {
    navigate('/blog')
  }

  return (
    <PublicLayout>
      <Box className={classes.defaultSection}>
        <Container fluid className={classes.section}>
          <Box className={classes.paddingPage}>
            <Grid gutter='xl' align='center'>
              <Grid.Col span={{ base: 12, md: 12, lg: 3 }}>
                <Box ta="center">
                  <Image src={LajuReaksiLogo} alt='laju-reaksi-logo' />
                </Box>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 8 }} offset={{ base: 0, lg: 1 }}>
                <Box>
                  <Text className={classes.titleSection} mb={8}>Laju Reaksi by Katalis Talenta Indonesia</Text>
                  <Text className={classes.captionSection}>merupakan platform konsultasi, pelatihan, dan sertifikasi kompetensi. Dirancang untuk memenuhi kebutuhan profesi dan perusahaan yang berfokus pada tanggung jawab lingkungan, platform ini menawarkan akses mudah ke berbagai sumber daya yang relevan dan interaktif.</Text>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className={classes.customSection}>
        <Container fluid className={classes.section}>
          <Box className={classes.paddingPage}>
            <Grid gutter='xl' align='center'>
              <Grid.Col span={{ base: 12, md: 12, lg: 3 }} order={2} offset={{ base: 0, lg: 1 }}>
                <Box ta="center">
                  <Image src={KatalisLogo} alt='katalis-logo' />
                </Box>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 8 }}  order={1}>
                <Box>
                  <Text className={classes.titleSectionDefault} mb={8}>PT Katalis Talenta Indonesia</Text>
                  <Text className={classes.captionSectionWhite}>merupakan perusahan Jasa Konsultasi Lingkungan dan Pelatihan. Berkomitmen untuk mempercepat pergerakan perseorangan maupun perusahaan dengan menjalin kolaborasi yang baik. </Text>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className={classes.defaultSection}>
        <Container fluid className={classes.section}>
          <Box className={classes.paddingPage}>
            <Box ta="center" mb={50}>
              <Text className={classes.titleSection}>Kontak Kami</Text>
            </Box>
            <Grid gap gutter='xl' align='start'>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <Flex justify='center' align='center'>
                  <Paper shadow="md" withBorder radius='lg'>
                    <Box component='iframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.53284374978!2d112.81070721116454!3d-7.293869092683257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f10ed79c5c63%3A0x623188b251d2b0b1!2sPT%20Katalis%20Talenta%20Indonesia!5e0!3m2!1sid!2sid!4v1700674423186!5m2!1sid!2sid" miw={{ base: 500, lg: width / 2.5 }} height={height / 2.5} style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Box>
                  </Paper>
                </Flex>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }} >
                <Box>
                  <Text className={classes.titleSection} mb={24}>Hubungi Kami</Text>
                  <Box>
                    <Flex gap='md' justify='start' align='center' mb={22}>
                      <ActionIcon variant='filled' color="orange" size="xl" radius="xl">
                        <IconPhone stroke={1.5} />
                      </ActionIcon>
                      <Box>
                        <Text fz={26} fw='bold'>Telepon</Text>
                        <Text fz={14} >+62 811-6670-6262</Text>
                      </Box>
                    </Flex>
                    <Flex gap='md' justify='start' align='center' mb={22}>
                      <ActionIcon variant='filled' color="orange" size="xl" radius="xl">
                        <IconMail stroke={1.5} />
                      </ActionIcon>
                      <Box>
                        <Text fz={26} fw='bold'>Email</Text>
                        <Text fz={14} >katalis.talenta@gmail.com</Text>
                      </Box>
                    </Flex>
                    <Flex gap='md' justify='start' align='center' mb={22}>
                      <ActionIcon variant='filled' color="orange" size="xl" radius="xl">
                        <IconMapPin stroke={1.5} />
                      </ActionIcon>
                      <Box>
                        <Text fz={26} fw='bold'>Alamat</Text>
                        <Text fz={14} >Eastern Park Residence, Jl. Raya Sukolilo Mulia, Ruko A-05, Keputih, Kec. Sukolilo, Kota SBY, Jawa Timur, 60111.</Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Container>
      </Box>
      <ConsultantSection caption={defaultCaption.text} captionButton={defaultCaption.button} ctaButton={handleRoute} />
    </PublicLayout>
    
  )
}

export default About
import React, { useEffect, useState } from 'react'
import MemberLayout from '../../../layouts/MemberLayout'
import { Accordion, Box, Skeleton, Title, Text, Flex, Card, Divider, Badge, Button, Image } from '@mantine/core'
import { getImageFile } from '../../../services/fileImage'
import { formatPrice } from '../../../plugins/formatPrice'
import { useNavigate } from 'react-router-dom'
import { getTransactionList } from '../../../services/transaction'
import classes from '../MemberPage.module.css'
import dayjs from 'dayjs'
import CountdownTimer from '../../../components/ui/CountdownTimer'
import { modalConfirmTransaction } from '../../../components/ui/ModalManager/modalConfirmTransaction'
import { cancelTransaction } from '../../../services/transaction'


const defaultParameter = {
  skip: '',
  take: '',
  search: '',
  fromDate: '',
  toDate: '',
  order: 'desc'
}

const badgeStatus = {
  pending: {
    label: 'Menunggu Pembayaran',
    color: 'yellow'
  },
  fail: {
    label: 'Gagal',
    color: 'red'
  },
  success: {
    label: 'Berhasil',
    color: 'green'
  }
}

const Transaction = () => {
  const navigate = useNavigate()
  const [ transactionList, setTransactionList ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const handleGetTransaction = async () => {
    setLoading(true)
    try {
      const response = await getTransactionList(defaultParameter)
      setTransactionList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetTransaction()
    // eslint-disable-next-line
  }, [defaultParameter])

  const confirmAbortTransaction = (val) => {
    modalConfirmTransaction('Batalkan Transaksi', val.id, val.invoiceId, cancelTransaction, handleGetTransaction)
  }

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i}>
        <Skeleton height={170} width="100%" radius="lg" mb="xl" />
      </Box>
    )
  })

  const mappingTransaction = (dataArray) => dataArray.map((val) => {
    return (
      <Accordion.Item value={val.id} key={val.id} style={{ cursor: 'pointer' }}>
        <Card padding='sm' radius='xs' withBorder>
          <Flex justify="space-between" align="center">
            <Text fz="xs">{dayjs(val.createdAt).format("DD-MMM-YYYY")}</Text>
            <Badge size="xs" radius="sm" variant="filled" color={badgeStatus[val.status].color}>{badgeStatus[val.status].label}</Badge>
          </Flex>
          <Divider my="xs" />
          <Accordion.Control>
            <Flex align="center">
              <Box>
                <Image w={65} src={val.transactionMethod !== null ? getImageFile(val.transactionMethod.image) : null} alt={val.name} />
              </Box>
              <Box ml={20}>
                <Text fz="xs" fw="bold"> {val.transactionMethod !== null ? val.transactionMethod.name : 'No Payment Method'}</Text>
                <Text fz="xs"> {formatPrice(val.amount)}</Text>
              </Box>
            </Flex>
          </Accordion.Control>
          <Divider my="xs" />
          <Flex justify="space-between" align="center">
            <Text fz="xs">INVOICE ID: {val.invoiceId}</Text>
            <Button size="xs" compact onClick={() => navigate(`/transaction/${val.invoiceId}`)}>Detail</Button>
          </Flex>
        </Card>
        <Accordion.Panel>
          <Box px={10} py={20}>
            {val.status === 'pending' ? (
              <Box mt={12}>
                <Text fz="xs" fw="bold">Pembayaran Kedaluarsa Dalam:</Text>
                <CountdownTimer targetDate={val.expireDate} fz={12} fw={400} sizeBadge='sm' />
                <Flex justify='end' mt={12}>
                  <Button size='xs' onClick={() => confirmAbortTransaction(val)} variant="filled" color="red">Batalkan Transaksi</Button>
                </Flex>
              </Box>
            ) : (<Text fz="xs">Status: Transaksi {badgeStatus[val.status].label}</Text>)}
          </Box>
        </Accordion.Panel>
      </Accordion.Item>
    )
  })

  const TransactionData = ({dataTransaction}) => {
    if (dataTransaction.length > 0) {
      return (
        <Accordion variant="separated" chevronPosition="right" mx="auto">
          {mappingTransaction(dataTransaction)}
        </Accordion>
      )
    } else {
      return (
        <Box align="center" mt={70}>
          <Text fz="md" fw="bold">Kamu Belum Memiliki Transaksi</Text>
        </Box>
      )
    }
  }

  return (
    <MemberLayout>
      <Box p={12}>
        <Title className={classes.titleHeader}>Transaksi Saya</Title>
      </Box>
      <Box mt={30}>
        {loading ? loadingData(5) : (<TransactionData dataTransaction={transactionList} />)}
      </Box>
    </MemberLayout>
  )
}

export default Transaction
import React, { useEffect, useState } from 'react'
import { Box, Accordion, Group, Text, Divider, Grid, Card, Center, Image } from '@mantine/core'
import { IconBuildingBank } from '@tabler/icons-react'
import { getPaymentMethod } from '../../../services/transaction'
import { useSelector } from 'react-redux'
import classes from '../../../pages/Checkout/Checkout.module.css'
import { useDispatch } from 'react-redux'
import { update } from '../../../store/cartData'
import { getImageFile } from '../../../services/fileImage'


const PaymentSession = () => {
  const cartItems = useSelector(state => state.checkout.cart)
  const dispatch = useDispatch()
  const [payment, setPayment] = useState([])
  const [selectedPayment, setSelectedPayment] = useState(null)

  const handleGetPaymentMethod = async () => {
    try {
      const response = await getPaymentMethod()
      setPayment(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetPaymentMethod()
    setSelectedPayment(cartItems.transactionMethodId)
    //eslint-disable-next-line
  }, [])

  const handleSelectedPayment = (paymentId) => {
    setSelectedPayment(paymentId)
    const data = {
      transactionMethodId: paymentId,
      eventScheduleIds: cartItems.eventScheduleIds,
      eventSlug: cartItems.eventSlug,
      totalPrice: cartItems.totalPrice
    }
    if (cartItems.discountCode) {
      data.discountCode = cartItems.discountCode
    }
    if (cartItems.previousPrice) {
      data.previousPrice = cartItems.previousPrice
    }
    if (cartItems.totalVoucher) {
      data.totalVoucher = cartItems.totalVoucher
    }
    if (cartItems.typeVoucher) {
      data.typeVoucher = cartItems.typeVoucher
    }
    dispatch(update({ 'cart': data }))
  }

  const mappingPayment = payment.map((val) => {
    return (
      <Grid.Col span={{ base: 12, lg: 3 }} key={val.name}>
        <Card className={selectedPayment === val.id ? classes.activeCard : classes.cardBorder} shadow="sm" padding="lg" radius="md" h={110} onClick={() => handleSelectedPayment(val.id)}>
          <Center h={70} mx="auto">
            <Box>
              <Image src={val.image ? getImageFile(val.image) : null} alt={val.name} w="80" />
            </Box>
          </Center>
        </Card>
      </Grid.Col>
    )
  })

  return (
    <Box mb="xl">
      <Accordion variant="contained" defaultValue="payment">
        <Accordion.Item value="payment">
          <Accordion.Control>
            <Group noWrap>
              <IconBuildingBank color="blue" size="2.5rem" />
              <Box>
                <Text className={classes.titleSection} fw="bold">Metode Pembayaran</Text>
                <Text className={classes.captionSection} fz="md" c="gray.5" weight={400}>Pilih pembayaran sesuai dengan Bank yang anda miliki</Text>
              </Box>
            </Group>
          </Accordion.Control>
          <Accordion.Panel p={8}>
            <Divider mb="sm" />
            <Grid align="center" p={8}>
              {mappingPayment}
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}

export default PaymentSession
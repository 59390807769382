import React from 'react'
import MemberLayout from '../../../layouts/MemberLayout'
import classes from '../MemberPage.module.css'
import { Box , Text, Grid} from '@mantine/core'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import StatisticMember from '../../../components/pages/Member/Dashboard/StatisticMember'
import TransactionMember from '../../../components/pages/Member/Dashboard/TransactionMember'
import ProgramMember from '../../../components/pages/Member/Dashboard/ProgramMember'
import UpcomingProgramMember from '../../../components/pages/Member/Dashboard/UpcomingProgramMember'

const Dashboard = () => {
  const { isLoading, data } = useSelector(state => state.member)
  const hours = dayjs(new Date()).format("HH")

  const greeting = (time) => {
    if (time > 16) {
      return "Selamat Malam"
    } else if (time > 11) {
      return "Selamat Siang"
    } else {
      return "Selamat Pagi"
    }
  }

  return (
    <MemberLayout>
      <Box p={12}>
        <Box>
          <Box>
            <Text component='span' className={classes.titleHeader} fw='bold'>{greeting(hours)}, </Text>
            <Text component='span' className={classes.titleHeader} fw='bold'>{isLoading ? '' : data.fullName}</Text>
          </Box>
        </Box>
        <Box mt={30}>
          <Grid gutter='xl'>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <StatisticMember />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <TransactionMember />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <ProgramMember />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <UpcomingProgramMember />
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </MemberLayout>
  )
}

export default Dashboard
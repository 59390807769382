import React from 'react'
import MemberLayout from '../../../layouts/MemberLayout'
import { Accordion, Box, Title, Divider, Text } from '@mantine/core'
import classes from '../MemberPage.module.css'
import { IconUserEdit, IconLock } from '@tabler/icons-react'
import FormProfile from '../../../components/pages/Member/Profile/FormProfile'
import FormPassword from '../../../components/pages/Member/Profile/FormPassword'


const settingProfile = [
  {
    label: 'Edit Profile',
    key: 'profile',
    icons: IconUserEdit
  },
  {
    label: 'Edit Password',
    key: 'password',
    icons: IconLock
  }
]

const Profile = () => {

  const mappingFormSetting = (key) => {
    if (key === 'profile') {
      return (<FormProfile/>)
    } else {
      return (<FormPassword />)
    }
  }

  const mappingSetting = (setting) => setting.map((val, index) => {
    return (
      <Accordion.Item key={index} value={val.key} style={{ cursor: 'pointer', border: '1px solid #C1C2C5' }}>
        <Accordion.Control icon={<val.icons size="1rem" />}>
          <Text fz="sm" fw="bold">{val.label}</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Divider mb="md" />
          {mappingFormSetting(val.key)}
        </Accordion.Panel>
      </Accordion.Item>
    )
  })
  return (
    <MemberLayout>
      <Box p={12}>
        <Title className={classes.titleHeader}>Pengaturan Profil</Title>
      </Box>
      <Box mt={20} px={10}>
        <Accordion variant='separated' radius='sm'>
          {mappingSetting(settingProfile)}
        </Accordion>
      </Box>
    </MemberLayout>
  )  
}

export default Profile
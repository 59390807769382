import React from 'react'
import { Box, Card, Skeleton } from '@mantine/core'

const SkeletonCard = () => {
  return (
    <Box>
      <Card shadow='sm' padding='lg' radius='md' withBorder>
        <Card.Section>
          <Skeleton height={300} width="100%" mb="xl" />
        </Card.Section>
        <Box h={60} mt={10}>
          <Skeleton height={16} radius="xl" />
          <Skeleton height={16} mt={8} radius="xl" />
          <Skeleton height={16} mt={8} width="70%" radius="xl" />
        </Box>
      </Card>
    </Box>
    
  )
}

export default SkeletonCard
import React, { useState } from 'react'
import { useViewportSize } from '@mantine/hooks'
import { Box, Button, Grid, ScrollArea, Text, TextInput, PasswordInput, Anchor, Modal, List, Flex } from '@mantine/core'
import classes from './Login.module.css'
import AuthImage from '../../assets/img/RegisterImage.png'
import { useNavigate } from 'react-router-dom' 
import { IconArrowLeft } from '@tabler/icons-react'
import { notificationSuccess, notificationError } from '../../components/ui/Notification'
import Cookies from 'universal-cookie'
import { encodedText } from '../../plugins/encrypt'
import { login } from '../../services/auth'
import http from '../../plugins/axios'
import { validation } from '../../plugins/validation'
import { useDispatch } from 'react-redux'
import { update } from '../../store/authData'
import { getAuthMember } from '../../store/authMember'



const defaultVal = {
  email: '',
  password: ''
}

const formValidation = {
  email: {
    isError: false,
    message: ''
  },
  password: {
    isError: false,
    message: ''
  }
}

const Login = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width, height } = useViewportSize()
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loading, setLoading] = useState(false)
  const [modalStep, openModalStep] = useState(false)
  

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setValidationForm(formValidation)
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    try {
      const response = await login(formData)
      if (response.role === 'Peserta') {
        notificationSuccess('Login Berhasil', `Selamat datang ${response.fullName}`)
        http.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
        const stringValue = JSON.stringify(response)
        const encodedData = encodedText(stringValue)
        cookies.set('session', encodedData, { path: '/' })
        dispatch(update({ 'authorization': response, 'isLogin': true, 'isLoading': false }))
        dispatch(getAuthMember())
        const routePath = localStorage.getItem('route')
        if (routePath) {
          const path = JSON.parse(routePath)
          navigate(path)
          localStorage.removeItem('route')
        } else {
          navigate('/dashboard')
        }
      } else {
        notificationError('Login Gagal', `Role anda bukan untuk peserta`)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Login Gagal', `${errorMessage ? errorMessage : 'Silahkan periksa kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
    
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <Box>
      <Box style={{ display: 'flex' }}>
        <Box pos="sticky">
          <Box className={classes.authLayout} style={{ backgroundImage: `url(${AuthImage})`, minHeight: `${height}px`, minWidth: `${width / 2.5}px`}}/>
        </Box>
        <ScrollArea h={height} w={width}>
          <Box p={20}>
            <Box mb={60}>
              <Button variant="subtle" leftSection={<IconArrowLeft size={14} />} onClick={() => navigate("/")}>Kembali ke Homepage</Button>
            </Box>
            <Box align="center">
              <Grid justify='center' align='center'>
                <Grid.Col span={8}>
                  <Box mb={24}>
                    <Text className={classes.titleHeader}>Selamat Datang</Text>
                  </Box>
                  <Box align="left">
                    <form onSubmit={(e) => {handleSubmit(e)}}>
                      <Box mb="md">
                        <TextInput
                          name="email"
                          placeholder="Masukkan e-mail kamu"
                          label="E-mail"
                          error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                      <Box mb="md">
                        <PasswordInput
                          name="password"
                          placeholder="Masukkan password kamu"
                          label="Password"
                          error={validationForm.password.isError ? validationForm.password.message : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                      <Box style={{ margin: '30px 0' }}>
                        <Button fullWidth color="red" loading={loading} type="submit">Login</Button>
                      </Box>
                    </form>
                    <Box align="center" mt={20} mb={10}>
                      <Anchor component="button" c="red" fz={13} onClick={() => navigate('/password-resets')}>Lupa password</Anchor>
                    </Box>
                    <Box align="center">
                      <Text fz={13} mb={4}>Belum Bergabung? <Text fz={13} component="span" c="red" onClick={() => navigate("/register")} style={{ cursor:'pointer' }}>Daftar</Text></Text>
                      <Text fz={13} component="span" c="red" onClick={() => openModalStep(true)} style={{ cursor:'pointer' }}>Panduan Registrasi</Text>
                    </Box>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </ScrollArea>
      </Box>
      <Modal opened={modalStep} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>Cara Registrasi </Text>} withCloseButton={false}>
        <Box>
          <List type="ordered" withPadding>
            <List.Item>Buka website lajureaksi.co.id</List.Item>
            <List.Item>Buka tombol Daftar</List.Item>
            <List.Item>Isi form registrasi pendaftaran data diri, email dan password</List.Item>
            <List.Item>Aktivasi akun dengan cara verifikasi di email pendaftar (email yang didaftarkan), mohon cek SPAM apabila email verifikasi tidak masuk kedalam Kotak Masuk (Inbox)</List.Item>
            <List.Item>Kembali ke website  lajureaksi.co.id dan langsung masuk ke tombol Login</List.Item>
          </List>
          <Box mt={20}>
            <Flex justify='flex-end'>
              <Button variant="outline" color='indigo.9' size='xs' onClick={() => openModalStep(false)}>Tutup</Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Login
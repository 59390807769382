import React, { useState } from 'react'
import { Box, PasswordInput, Progress, Popover, Group, Flex, Button } from '@mantine/core'
import PasswordRequired from '../../../ui/PasswordRequired'
import { validation } from '../../../../plugins/validation'
import { useSelector } from 'react-redux'
import { notificationSuccess, notificationError } from '../../../ui/Notification'
import { updateUserPassword } from '../../../../services/auth'

const defaultFormPassword = {
  oldPassword: '',
  newPassword: '',
  verifyPassword: ''
}

const formValidationPassword = {
  oldPassword: {
    isError: false,
    message: ''
  },
  newPassword: {
    isError: false,
    message: ''
  },
  verifyPassword: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const FormPassword = () => {
  const loginData = useSelector(state => state.member.data)
  const [formData, setFormData] = useState(defaultFormPassword)
  const [formValidation, setFormValidation] = useState(formValidationPassword)
  const [ loading, setLoading ] = useState(false)
  const [popoverPassword, setPopoverPassword] = useState(false)

  const submitUpdatePassword = async () => {
    setLoading(true)
    setFormValidation(formValidationPassword)
    const isError = validation(formData, setFormValidation)
    if (isError) {
      setLoading(false)
      return
    }
    if (formData.newPassword !== formData.verifyPassword) {
      setFormValidation((old) => ({
        ...old,
        verifyPassword: {
          isError: true,
          message: 'Konfirmasi password harus sama dengan password baru anda'
        }
      }))
      setLoading(false)
      return
    }
    const payload = {
      oldPassword: formData.oldPassword,
      newPassword: formData.verifyPassword
    }
    try {
      const response = await updateUserPassword(loginData.id, payload)
      if (response) {
        setLoading(false)
        notificationSuccess('Update Password Berhasil', 'Password anda sudah diupdate')
        setFormData(defaultFormPassword)
      }
    } catch (error) {
      setLoading(false)
      const errorMessage = error.response.data.message
      notificationError('Update Password Gagal', 'Silahkan periksa kembali form anda')
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidationPassword).forEach((element) => {
          if (el.includes(element)) {
            setFormValidation((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.newPassword)} />
  ))

  const strengthPassword = getStrength(formData.newPassword)

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(formData.newPassword).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const handleChangeForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <Box>
      <Box mb='md'>
        <PasswordInput
          name="oldPassword"
          placeholder="Isi Password Lama Anda"
          label="Password Lama"
          error={formValidation.oldPassword.isError ? `${formValidation.oldPassword.message}` : ''}
          onChange={handleChangeForm}
          value={formData.oldPassword}
          withAsterisk
          mb={10}
        />
      </Box>
      <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
        <Popover.Target>
          <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
            <PasswordInput
              name="newPassword"
              value={formData.newPassword}
              placeholder="Masukkan password baru anda"
              label="Password Baru"
              error={formValidation.newPassword.isError ? `${formValidation.newPassword.message}` : ''}
              onChange={handleChangeForm}
              withAsterisk
              mb={10}
            />
          </Box>
        </Popover.Target>
        <Popover.Dropdown>
          <Group gap={5} grow mt="xs" mb="md">
            {progressBar}
          </Group>
          <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.newPassword.length > 7} />
          {checkPassword}
        </Popover.Dropdown>
      </Popover>
      <PasswordInput
        name="verifyPassword"
        placeholder="Isi Konfirmasi Password Baru Anda"
        label="Konfirmasi Password Baru"
        onChange={handleChangeForm}
        error={formValidation.verifyPassword.isError ? `${formValidation.verifyPassword.message}` : ''}
        withAsterisk
        value={formData.verifyPassword}
        mb={10}
      />
      <Box mt={20}>
        <Flex justify="flex-end">
          <Button loading={loading} color="red" onClick={() => submitUpdatePassword()}>Update Password</Button>
        </Flex>
      </Box>
    </Box>
    
  )
}

export default FormPassword
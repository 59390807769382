import React, { useState, useEffect } from 'react'
import classes from '../../../pages/Blog/Blog.module.css'
import { getBlogs } from '../../../services/blog'
import { Box, Container, Grid, Skeleton, Text, Flex, Image, Button } from '@mantine/core'
import dayjs from 'dayjs'
import { getImageFile } from '../../../services/fileImage'
import { useNavigate } from 'react-router-dom'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  order: 'desc'
}

const AllBlogSection = () => {
  const navigate = useNavigate()
  const [ params, setParams ] = useState(defaultParameter)
  const [ loading, setLoading ] = useState(true)
  const [ allBlog, setAllBlog ] = useState([])
  const [count, setCount] = useState(0)
  const handleAllDataBlog = async () => {
    setLoading(true)
    try {
      const response = await getBlogs(params)
      setAllBlog(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleAllDataBlog()
    //eslint-disable-next-line
  }, [params])

  const handlePage = (params) => {
    if (allBlog.length < count) {
      setParams({ ...params, 'take': params.take + 10 })
    }
  }

  const mappingTag = (array) => array.map((val, index) => {
    return (
      <Text key={index} fz="xs" color="red" mr={2}>{val.name}, </Text>
    )
  })

  const loadingVerticalBlog = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i} mb={24}>
        <Grid>
          <Grid.Col lg={2} md={12}>
            <Skeleton h={{ base:350, xl: 145, xs: 350 }} />
          </Grid.Col>
          <Grid.Col lg={10} md={12}>
            <Flex mb={20} style={{ width: '20%' }}>
              <Skeleton height={16} mr={12} />
              <Skeleton height={16} />
            </Flex>
            <Skeleton height={24} mb={12} />
            <Skeleton height={10} mb={6} />
            <Skeleton height={10} mb={12} />
            <Skeleton height={24} width="20%" />
          </Grid.Col>
        </Grid>
      </Box>
    )
  })

  const verticalBlog = (data) => {
    if (data.length > 0) {
      // eslint-disable-next-line
      const remap = data.map((val, index) => {
        if (val.isPublished) {
          return (
            <Box key={index} mb={24}>
              <Grid>
                <Grid.Col span={{ base: 12, lg: 2 }}>
                  <Image height={155} radius="lg" fit='cover' src={val.thumbnail !== null ? getImageFile(val.thumbnail) : null} alt={val.title} withPlaceholder />
                </Grid.Col>
                <Grid.Col span={{ base: 12, lg: 10 }}>
                  <Flex mb={2}>
                    <Flex>
                      {mappingTag(val.tags)}
                    </Flex>
                    <Text fz='xs' c="gray.7">{dayjs(val.createdAt).format('DD MMM YYYY')}</Text>
                  </Flex>
                  <Text className={classes.titleNews} mb={2} lineClamp={1}>{val.title}</Text>
                  <Box h={55} mb={12}>
                    <Text className={classes.captionNews} lineClamp={2} c="gray.6">{val.caption}</Text>
                  </Box>
                  <Button size='xs' style={{ backgroundColor: '#043056' }} onClick={() => navigate(`/blog/${val.slug}`)}>Baca Selengkapnya</Button>
                </Grid.Col>
              </Grid>
            </Box>
          )
        }
      })
      return remap
    }
  }


  return (
    <Box className={classes.blogSection} style={{ paddingTop: '10px' }}>
      <Container fluid className={classes.section}>
        <Box className={classes.paddingPage}>
          <Text className={classes.titleBlog} fw="bold">All Blog Post</Text>
          <Box mt={8}>
            {loading ? loadingVerticalBlog(10) : verticalBlog(allBlog)}
          </Box>
          <Box mt={30} align="center">
            {allBlog.length < count ? (<Button loading={loading} color='red' onClick={() => handlePage(params)}>Selengkapnya</Button>) : ''}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default AllBlogSection
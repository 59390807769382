import React, { useState } from 'react'
import { Box, Textarea, Button, Group, Flex } from '@mantine/core'
import { submitFeedbackUserProgram } from '../../../../services/userPrograms'
import { validation } from '../../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../ui/Notification'

const defaultVal = {
  feedback: ''
}

const formValidation = {
  feedback: {
    isError: false,
    message: ''
  }
}

const FormFeedbackProgram = ({ userCompletionId, onCloseModal, reloadDetail }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitFeedback = async (formFeedback) => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      feedback: formFeedback.feedback
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    const body = {
      userComment: payload.feedback
    }
    try {
      const response = await submitFeedbackUserProgram(userCompletionId, body)
      if (response.affected === 1) {
        onCloseModal()
        reloadDetail()
        notificationSuccess('Submit Feedback Berhasil', 'Terima kasih anda telah mengisi feedback dari training ini')
      }
    } catch (error) {
      console.log(error)
      notificationError('Gagal Submit Feedback', 'Silahkan cek kembali form anda')
    }
  }

  return (
    <Box>
      <Box mb='md'>
        <Textarea
          name='feedback'
          value={formData.feedback}
          label='Masukkan Feedbackmu'
          placeholder='Masukkan feedbackmu tentang program ini'
          error={validationForm.feedback.isError ? `${validationForm.feedback.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitFeedback(formData)}>Submit</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormFeedbackProgram
import React, { useState, useEffect } from 'react'
import { Box, Group, Text, Button } from '@mantine/core'
import classes from '../../../../pages/Member/MemberPage.module.css'
import { useNavigate } from 'react-router-dom'
import { IconCircleArrowRight } from '@tabler/icons-react'
import TableTransactionMember from './TableTransactionMember'
import { getTransactionList } from '../../../../services/transaction'

const labelTable = [
  {
    label: 'No.',
    position: 'center'
  },
  {
    label: 'Invoice ID',
    position: 'center'
  },
  {
    label: 'Status',
    position: 'center'
  },
  {
    label: 'Action',
    position: 'center'
  },
]

const defaultParameter = {
  take: 5,
  order: 'desc'
}

const TransactionMember = () => {
  const navigate = useNavigate()
  const [transactionList, setTransactionList] = useState([])

  const handleGetTransaction = async () => {
    try {
      const response = await getTransactionList(defaultParameter)
      setTransactionList(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransaction()
    // eslint-disable-next-line
  }, [])


  return (
    <Box>
      <Group justify='space-between' mb={12}>
        <Text className={classes.titleDashboard} fw='bold'>Transaksi Anda</Text>
        <Button rightSection={<IconCircleArrowRight />} color='yellow.7' radius="md" size='xs' onClick={() => navigate('/transaction')}>Selengkapnya</Button>
      </Group>
      <Box>
        <TableTransactionMember label={labelTable} data={transactionList} />
      </Box>
    </Box>
  )
}

export default TransactionMember
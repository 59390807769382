import React from 'react'
import { Box, Grid, Text, Group, Avatar, Card, Flex } from '@mantine/core'
import { getImageFile } from '../../../../services/fileImage'

const EmptyTutor = () => {
  return (
    <Grid.Col span={{ base: 12, lg: 4 }}>
      <Card withBorder p="xl" radius="md" style={{ backgroundColor: '#edf2ff' }}>
        <Group noWrap>
          <Avatar radius="xl" size={70} />
          <Box>
            <Text fz="md" fw="bold" tt="capitalize">Katalis Team</Text>
            <Text fz="sm" tt="capitalize">Tutor Profesional</Text>
          </Box>
        </Group>
      </Card>
    </Grid.Col>
  )
}

const TutorSection = ({ tutors }) => {

  const dataTutors = (data) => data.map((val) => {
    console.log(val)
    const innerHtml = { __html: val.shortProfile }
    return (
      <Grid.Col span={{ base: 12, lg: 4 }} key={val.id}>
        <Card shadow="sm" padding="md" radius="md" withBorder style={{ backgroundColor: '#edf2ff' }}>
          <Flex align='center'>
            <Avatar radius={60} mr={16} size={120} src={val.user.image !== "" ? getImageFile(val.user.image) : null} alt={val.user.fullName} />
            <Box>
              <Text mb={2} fz="sm" fw="bold">{val.user.fullName}</Text>
              <Text fz="xs" lineClamp={3}>{val.shortProfile ? (<Box dangerouslySetInnerHTML={innerHtml} />) : '-'}</Text>
            </Box>
          </Flex>
        </Card>
      </Grid.Col>
    )
  })
  return (
    <Box mb={35}>
      <Text fz="md" fw="bold">Pemateri</Text>
      <Box mt="md">
        <Grid align='center'>
          {tutors.length > 0 ? dataTutors(tutors) : <EmptyTutor />}
        </Grid>
      </Box>
    </Box>
  )
}

export default TutorSection
import React, { useState } from 'react'
import { useViewportSize } from '@mantine/hooks'
import { Box, Button, Grid, ScrollArea, Text, Popover, PasswordInput, Progress, Group } from '@mantine/core'
import classes from '../Login/Login.module.css'
import { IconArrowLeft } from '@tabler/icons-react'
import { notificationError, notificationSuccess } from '../../components/ui/Notification'
import { validation } from '../../plugins/validation'
import { useNavigate, useLocation } from 'react-router-dom'
import { resetPasswordVerify } from '../../services/auth'
import AuthImage from '../../assets/img/RegisterImage.png'
import PasswordRequired from '../../components/ui/PasswordRequired'

const defaultVal = {
  password: '',
  verifyPassword: ''
}

const formValidation = {
  password: {
    isError: false,
    message: ''
  },
  verifyPassword: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const VerifiedPassword = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const resetToken = queryParams.get("token")
  const { width, height } = useViewportSize()
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [popoverPassword, setPopoverPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />
  ))
  const strengthPassword = getStrength(formData.password)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(formData.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setValidationForm(formValidation)
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    if (formData.password !== formData.verifyPassword) {
      setValidationForm((old) => ({
        ...old,
        verifyPassword: {
          isError: true,
          message: 'Password harus sama dengan password baru anda'
        }
      }))
      setLoading(false)
      return 
    }
    const payload = {
      password: formData.verifyPassword
    }
    try {
      const response = await resetPasswordVerify(resetToken, payload)
      if (response) {
        notificationSuccess('Perubahan Password Berhasil', `${response.message}`)
        navigate("/login")
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Perubahan Password Gagal', `${errorMessage}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box display='flex'>
        <Box pos='sticky'>
          <Box className={classes.authLayout} style={{ backgroundImage: `url(${AuthImage})`, minHeight: `${height}px`, minWidth: `${width / 2.5}px`}} />
        </Box>
        <ScrollArea h={height} w={width}>
          <Box p={20}>
            <Box mb={60}>
              <Button variant="subtle" leftSection={<IconArrowLeft size={14} />} onClick={() => navigate("/")}>Kembali ke Homepage</Button>
            </Box>
            <Box align="center">
              <Grid justify='center' align='center'>
                <Grid.Col span={8}>
                  <Box mb={8}>
                    <Text className={classes.titleHeader}>Laju Reaksi</Text>
                  </Box>
                  <Box mb={14}>
                    <Text fz={{ base: 13, lg: 18 }} fw="bold">Silahkan Masukkan Perubahan Password Anda</Text>
                  </Box>
                  <Box align="left">
                    <form onSubmit={(e) => {handleSubmit(e)}}>
                      <Box mb="xl">
                        <Box mb="md">
                          <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                            <Popover.Target>
                              <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                                <PasswordInput
                                  name="password"
                                  value={formData.password}
                                  placeholder="isi password baru"
                                  label="Password Baru"
                                  error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                                  onChange={handleChange}
                                  withAsterisk
                                />
                              </Box>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Group gap={5} grow mt="xs" mb="md">
                                {progressBar}
                              </Group>
                              <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
                              {checkPassword}
                            </Popover.Dropdown>
                          </Popover>
                        </Box>
                        <Box>
                          <PasswordInput
                            name="verifyPassword"
                            placeholder="ulangi password baru"
                            label="Konfirmasi password baru"
                            error={validationForm.verifyPassword.isError ? `${validationForm.verifyPassword.message}` : ''}
                            onChange={handleChange}
                            withAsterisk
                          />
                        </Box>
                      </Box>
                      <Box style={{ margin: '30px 0' }}>
                        <Button fullWidth color="red" loading={loading} type="submit">Ubah Password</Button>
                      </Box>
                    </form>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </ScrollArea>
      </Box>
    </Box>
  )
}

export default VerifiedPassword
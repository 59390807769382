import React from 'react'
import classes from '../../../pages/Blog/Blog.module.css'
import { Box, Container, Grid, Text, Button } from '@mantine/core'
import BlogLogo from '../../../assets/img/blogHeader.png'

const BlogHeader = ({ handleScroll }) => {
  return (
    <Box className={classes.headerSection} style={{ backgroundImage: `url(${BlogLogo}?dim=500x500)`}}>
      <Container fluid className={classes.section}>
        <Box className={classes.innerHeader}>
          <Box className={classes.contentHeader}>
            <Grid justify="start" align="center">
              <Grid.Col span={{ base: 12, lg: 8 }}>
                <Box className={classes.paddingPage}> 
                  <Text className={classes.titleHeader} mb={16}>Artikel dan Pendampingan Pelatihan oleh Kami</Text>
                  <Button color="red.9" className={classes.buttonHeader} fz={16} radius="md" onClick={handleScroll}>
                    <Text p={10} fw="bold">Mulai Membaca</Text>
                  </Button>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default BlogHeader
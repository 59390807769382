import React from 'react'
import { Box } from '@mantine/core'
import PublicLayout from '../../layouts/PublicLayout'
import { useScrollIntoView } from '@mantine/hooks'
import HeaderSection from '../../components/pages/Home/HeaderSection'
import IntroSection from '../../components/pages/Home/IntroSection'
import TrainingSection from '../../components/pages/Home/TrainingSection'
import PartnerSection from '../../components/pages/Home/PartnerSection'
import ConsultantSection from '../../components/pages/Home/ConsultantSection'

const Home = () => {
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60
  })

  const defaultCaption = {
    text: 'Konsultasikan Dengan Kami!',
    button: 'Jika ingin bertanya, Klik saja'
  }

  const handleCta = () => {
    window.open(`https://api.whatsapp.com/send?phone=6281166706262`, '_blank')
  }


  return (
    <PublicLayout>
      <HeaderSection handleScroll={() => scrollIntoView({alignment: 'center'})} />
      <IntroSection />
      <Box pos='absolute' ref={targetRef} style={{ height: '90vh' }} />
      <TrainingSection type='training' />
      <TrainingSection type='bootcamp' />
      <PartnerSection />
      <ConsultantSection caption={defaultCaption.text} captionButton={defaultCaption.button} ctaButton={handleCta} />
    </PublicLayout>
    
  )
}

export default Home
import React, { useState, useEffect } from 'react'
import { getDetailContent, finishedContentProgress, getDetailUserProgram } from '../../../../services/userPrograms'
import { useParams } from 'react-router-dom'
import { Box, Center, Loader, Text, Button } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { update } from '../../../../store/memberProgram'
import { Viewer } from '@react-pdf-viewer/core'
import { DocumentViewer } from 'react-documents'
import { IconDownload } from '@tabler/icons-react'

// plugins pdf viewer
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

// css pdf viewer
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const LoadingQuestion = () => {
  return (
    <Box pos="relative"> 
      <Center mih={500} mx="auto">
        <Loader size="xl" />
      </Center>
    </Box>
  )
}

const ModuleProgram = ({ detail }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const programId = params.id
  const [detailContent, setDetailContent] = useState(null)
  const [loading, setLoading] = useState(true)
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const handleDetailProgram = async () => {
    try {
      const response = await getDetailUserProgram(programId)
      dispatch(update({ 'data': response }))
    } catch (error) {
      console.log(error)  
    } 
  }

  const handleGetDetailContent = async (contentId) => {
    setLoading(true)
    try {
      const response = await getDetailContent(programId, contentId)
      if (response) {
        setDetailContent(response)
        handleFinishedProgress(response.id)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    } 
  }
  
  const handleFinishedProgress = async(contentId) => {
    const payload = {
      status: 'passed'
    }
    try {
      await finishedContentProgress(programId, contentId, payload)
      await handleDetailProgram()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const previewFileStorage = () => {
    if (detailContent.module.includes('.pdf')) {
      return (
        <Viewer
          fileUrl={`${process.env.REACT_APP_SERVER_API_URL}/files/storage/${detailContent.module}`}
          plugins={[
            defaultLayoutPluginInstance
          ]}
        />
      )
    } else {
      return (
        <DocumentViewer
          viewerUrl={'https://docs.google.com/gview?url=%URL%&embedded=true'}
          url={`${process.env.REACT_APP_SERVER_API_URL}/files/storage/${detailContent.module}`}
          style={{ width:'100%', height: '600px' }}
        />
      )
    }
    
  }

  const downloadFileUrl = () => {
    return (
      <Box>
        <Button leftSection={<IconDownload size="1rem" />} color='green' onClick={() => window.open(`https://${detailContent.module}`, '_blank')}>Download Materi</Button>
      </Box>
    )
  }

  

  useEffect(() => {
    if (detail !== null) {
      handleGetDetailContent(detail.id)
    }
  //eslint-disable-next-line
  }, [detail])

  const loadDataModule = (detailContent) => {
    console.log('ini module', detailContent.module)
    return (
      <Box mih={500} pos='relative'>
        <Text fz={20} fw="bold">Modul Kurikulum {detailContent.name}</Text>
        <Box mt={10}>
          <Text>{detailContent.description ? detailContent.description : '-'}</Text>
        </Box>
        <Box mt={20} h={600}>
          {detailContent.type !== 'url' ? previewFileStorage() : downloadFileUrl()}
        </Box>
        
      </Box>
    )
  }

  return (
    <Box>
      {loading ? (<Box mih={500} pos='relative'><LoadingQuestion /></Box>) : loadDataModule(detailContent)}
    </Box>
  )
}

export default ModuleProgram
import React from 'react'
import Countdown from '../../../plugins/Countdown'
import { Box, Text, Badge } from '@mantine/core'
import dayjs from 'dayjs'

const ExpiredNotice = ({ size }) => {
  return (
    <Box>
      <Badge color='red' size={size}> Waktu habis </Badge>
    </Box>
  )
}

const ShowCounter = ({ days, hours, minutes, seconds, fz, fw }) => {
  return (
    <Box>
      <Text fz={fz} fw={fw} > {days} hari, {hours} jam, {minutes} menit, {seconds} detik  </Text>
    </Box>
  )
}

const CountdownTimer = ({ targetDate, fz, fw, sizeBadge }) => {
  const [days, hours, minutes, seconds] = Countdown(new Date(dayjs(targetDate).format("YYYY-MM-DDTHH:MM")))
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice size={sizeBadge} />;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} fz={fz} fw={fw} />;
  }
}

export default CountdownTimer
import React from 'react'
import ProtectedRoute from '../middleware/ProtectedRoute'
import { AppShell } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import HeaderMember from '../components/ui/AppShell/HeaderMember'
import SidebarMember from '../components/ui/AppShell/SidebarMember'

const MemberLayout = ({ children }) => {
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true)
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure()
  return (
    <ProtectedRoute>
      <AppShell
        header={{ height: 80 }}
        navbar={{
          width: { base: 200, lg: 300 },
          breakpoint: 'sm',
          collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop }
        }}
        padding='md'
      >
        <AppShell.Header>
          <HeaderMember mobileOpened={openSidebarMobile} mobileToggle={toggleMobile} desktopOpened={openSidebarDesktop} desktopToggle={toggleDesktop} />
        </AppShell.Header>
        <AppShell.Navbar>
          <SidebarMember />
        </AppShell.Navbar>
        <AppShell.Main>
          { children }
        </AppShell.Main>
      </AppShell>
    </ProtectedRoute>
    
  )
}

export default MemberLayout
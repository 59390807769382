import React, { useState } from 'react'
import { useViewportSize } from '@mantine/hooks'
import { Box, Button, Grid, ScrollArea, Text, TextInput, PasswordInput, Progress, Popover, Group } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import AuthImage from '../../assets/img/RegisterImage.png'
import classes from '../Login/Login.module.css'
import { IconArrowLeft } from '@tabler/icons-react'
import PasswordRequired from '../../components/ui/PasswordRequired'
import { register } from '../../services/auth'
import { validation } from '../../plugins/validation'
import { notificationSuccess, notificationError } from '../../components/ui/Notification'

const defaultVal = {
  fullName: '',
  email: '',
  password: '',
  verifyPassword: ''
}

const formValidation = {
  fullName: {
    isError: false,
    message: ''
  },
  email: {
    isError: false,
    message: ''
  },
  password: {
    isError: false,
    message: ''
  },
  verifyPassword: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const Register = () => {
  const navigate = useNavigate()
  const { width, height } = useViewportSize()
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loading, setLoading] = useState(false)
  const [popoverPassword, setPopoverPassword] = useState(false)
  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />
  ))
  const strengthPassword = getStrength(formData.password)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(formData.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const handleSubmit = async () => {
    setLoading(true)
    setValidationForm(formValidation)
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    if (formData.password !== formData.verifyPassword) {
      setValidationForm((old) => ({
        ...old,
        verifyPassword: {
          isError: true,
          message: 'Konfirmasi password harus sama dengan password anda'
        }
      }))
      setLoading(false)
      return 
    }
    const payload = {
      fullName: formData.fullName,
      email: formData.email,
      password: formData.verifyPassword,
      verifyUrl: `lajureaksi.co.id/auth/verify`
    }
    try {
      const response = await register(payload)
      if (response) {
        notificationSuccess('Registrasi Berhasil', `${response.message}`)
        navigate('/login')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Gagal Registrasi Akun', `${Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }


  return (
    <Box>
      <Box style={{ display: 'flex' }}>
        <Box pos="sticky">
          <Box className={classes.authLayout} style={{ backgroundImage: `url(${AuthImage})`, minHeight: `${height}px`, minWidth: `${width / 2.5}px`}}/>
        </Box>
        <ScrollArea h={height} w={width}>
          <Box p={20}>
            <Box mb={60}>
              <Button variant="subtle" leftSection={<IconArrowLeft size={14} />} onClick={() => navigate("/")}>Kembali ke Homepage</Button>
            </Box>
            <Box align="center">
              <Grid justify='center' align='center'>
                <Grid.Col span={8}>
                  <Box mb={24}>
                    <Text className={classes.titleHeader}>Bergabung dengan kami</Text>
                  </Box>
                  <Box align="left">
                    <Box mb="md">
                      <TextInput
                        name="fullName"
                        placeholder="Masukkan nama lengkap kamu"
                        label="Nama Lengkap"
                        error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ''}
                        onChange={handleChange}
                        withAsterisk
                      />
                    </Box>
                    <Box mb="md">
                      <TextInput
                        name="email"
                        placeholder="Masukkan e-mail kamu"
                        label="E-mail"
                        error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
                        onChange={handleChange}
                        withAsterisk
                      />
                    </Box>
                    <Box mb="md">
                      <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                        <Popover.Target>
                          <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                            <PasswordInput
                              name="password"
                              value={formData.password}
                              placeholder="Masukkan password"
                              label="Password"
                              error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                              onChange={handleChange}
                              withAsterisk
                            />
                          </Box>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Group gap={5} grow mt="xs" mb="md">
                            {progressBar}
                          </Group>
                          <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
                          {checkPassword}
                        </Popover.Dropdown>
                      </Popover>
                    </Box>
                    <Box mb="md">
                      <PasswordInput
                        name="verifyPassword"
                        placeholder="Ulangi password anda"
                        label="Konfirmasi Password"
                        error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ''}
                        onChange={handleChange}
                        withAsterisk
                      />
                    </Box>
                    <Box my={12}>
                      <Text fw={500} fz={13} c="red">*Wajib Diisi</Text>
                    </Box>
                    <Box style={{ margin: '30px 0' }}>
                      <Button fullWidth color="red" loading={loading} onClick={handleSubmit}>Daftar Sekarang</Button>
                    </Box>
                    <Box align="center">
                      <Text fz={14} mb={12}>Dengan mendaftar berarti saya setuju dengan S&K serta menerima email dari kami</Text>
                      <Text fz={13} mb={6}>Punya Akun? <Text fz={13} component="span" c="red" onClick={() => navigate("/login")} style={{ cursor:'pointer' }}>Masuk</Text></Text>
                      <Text fz={13}>Lupa Password? <Text fz={13} component="span" c="red" onClick={() => navigate("/password-resets")} style={{ cursor:'pointer' }}>Ganti Password</Text></Text>
                    </Box>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </ScrollArea>
      </Box>
    </Box>
  )
}

export default Register
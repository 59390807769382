import React from 'react'
import { Box, Container, Grid, Text, Button } from '@mantine/core'
import BootcampLogo from '../../../assets/img/bootcampHeader.svg'
import classes from '../../../pages/Bootcamp/Bootcamp.module.css'

const ProgramHeader = ({ handleScroll }) => {
  return (
    <Box className={classes.headerSection} style={{ backgroundImage: `url(${BootcampLogo}?dim=500x500)`}}>
      <Container fluid className={classes.section}>
        <Box className={classes.innerHeader}>
          <Box className={classes.contentHeader}>
            <Grid justify="start" align="center">
              <Grid.Col span={{ base: 12, lg: 7 }}>
                <Box className={classes.paddingPage}> 
                  <Text className={classes.titleHeader} mb={16}>Upgrade <Text component="span" color="orange" inherit>Pengetahuan</Text> untuk <Text component="span" color="orange" inherit>Lingkungan</Text> yang Lebih Baik</Text>
                  <Text className={classes.captionHeader} mb={24}>Tingkatkan Kualitas Lingkungan Bersama Ahli Kami dengan pembelajaran yang telah disesuaikan dengan kebutuhan industri yang berwawasan lingkungan. </Text>
                  <Button color="indigo.9" className={classes.buttonHeader} fz={16} radius="md" onClick={handleScroll}>
                    <Text p={10} fw="bold">Pilih Bootcamp</Text>
                  </Button>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ProgramHeader
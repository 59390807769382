import React, { useEffect, useState } from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import ProgramHeader from '../../components/pages/Program/ProgramHeader'
import { Box, Container, Text, Grid, Button, Select, TextInput } from '@mantine/core'
import { getProgramList } from '../../services/program'
import classes from './Bootcamp.module.css'
import SkeletonCard from '../../components/ui/SekeletonCard'
import ProgramCard from '../../components/pages/Program/ProgramCard'
import { useScrollIntoView } from '@mantine/hooks'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'

const defaultParameter = {
  skip: 0,
  take: 8,
  search: '',
  fromDate: '',
  toDate: '',
  order: 'desc',
  kind: 'bootcamp'
}

const defaultOrder = [
  {
    label: 'Terbaru',
    value: 'desc'
  },
  {
    label: 'Terlama',
    value: 'asc'
  }
]

const Bootcamp = () => {
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60
  })
  const [params, setParams] = useState(defaultParameter)
  const [bootcampList, setBootcampList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleGetBootcampList = async () => {
    setLoading(true)
    try {
      const response = await getProgramList(params)
      setBootcampList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetBootcampList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value })
  }

  const handleChangeOrder = (e) => {
    setParams({ ...params, 'order': e })
  }

  const handleChangeDate = (e) => {
    if (e[1] !== null) {
      setParams({ ...params, 'fromDate': dayjs(e[0]).format('YYYY-MM-DD'), 'toDate': dayjs(e[1]).format('YYYY-MM-DD') })
    } else {
      setParams({ ...params, 'fromDate': '', 'toDate': '' })
    }
  }

  const handlePage = (params) => {
    if (bootcampList.length < count) {
      setParams({ ...params, 'take': params.take + 8 })
    }
  }

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Grid.Col span={{base: 6, md: 6, lg: 3 }} key={i}>
        <SkeletonCard />
      </Grid.Col>
    )
  })

  const mappingDataBootcamp = (data) => {
    if (data.length > 0) {
      const remap = data.map((val, index) => {
        return (
          <Grid.Col span={{base: 6, md: 6, lg: 3 }} key={index}>
            <ProgramCard data={val} />
          </Grid.Col>
        )
      })
      return remap
    } else {
      return (
        <Grid.Col span={12}>
          <Box ta='center' py={60}>
            <Text c="dark">Bootcamp belum tersedia</Text>
          </Box>
        </Grid.Col>
      )
    }
  }


  return (
    <PublicLayout>
      <ProgramHeader handleScroll={() => scrollIntoView({alignment: 'center'})} />
      <Box className={classes.bootcampSection}>
        <Container fluid className={classes.section}>
          <Box className={classes.paddingPage} align="center">
            <Text className={classes.titleBootcamp}>BOOTCAMP</Text>
            <Text className={classes.captionBootcamp}>Program belajar intensif dengan mentor tenaga ahli yang berpengalaman</Text>
            <Box mt={30}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                  <TextInput
                    placeholder="Cari Bootcamp. . ."
                    name="search"
                    onChange={handleSearchChange}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                  <Select
                    name="order"
                    data={defaultOrder}
                    onChange={handleChangeOrder}
                    allowDeselect={false}
                    value={params.order}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                  <DatePickerInput
                    clearable
                    valueFormat="DD-MM-YYYY"
                    type="range"
                    placeholder="Pilih Tanggal Bootcamp"
                    onChange={handleChangeDate}
                    miw={220}
                  />
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
          <Box pos='absolute' ref={targetRef} style={{ height: '65vh' }} />
          <Box mt="xl">
            <Grid gutter='xl' className={classes.gridProgramCard} align="center">
              {loading ? loadingData(8) : mappingDataBootcamp(bootcampList)}
            </Grid>
          </Box>
          <Box mt={30} align="center">
            {bootcampList.length < count ? (<Button loading={loading} color='red' onClick={() => handlePage(params)}>Selengkapnya</Button>) : ''}
          </Box>
        </Container>
      </Box>
    </PublicLayout>
  )
}

export default Bootcamp
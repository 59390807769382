import React from 'react'
import { Box, Text, Grid, Flex, Button } from '@mantine/core' 
import { IconChevronRight } from '@tabler/icons-react'
import { useViewportSize } from '@mantine/hooks'
import ConsulImage from '../../../assets/img/ConsultImage.png'
import classes from '../../../pages/Home/Home.module.css'


const ConsultantSection = ({ caption, captionButton, ctaButton }) => {
  const { width } = useViewportSize()
  return (
    <Box className={classes.customSection} style={{ padding: 0 }}>
      <Box style={{ display: 'flex' }}>
        <Box className={classes.bgConsultant} style={{ backgroundImage: `url(${ConsulImage})`, minHeight: `516px`, minWidth: `${width / 2}px`}}></Box>
        <Box className={classes.consultantText} w={width}>
          <Grid className={classes.consultantDisplay}>
            <Grid.Col md={12}>
              <Flex justify="start" align="center">
                <Box>
                  <Text className={classes.consultantTitle} c="#ffff" mb={16}>{caption}</Text>
                  <Button rightSection={<IconChevronRight size={14} />} color='red' radius={12} onClick={ctaButton}>{captionButton}</Button>
                </Box>
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default ConsultantSection
import http from "../plugins/axios"

// get task by id
export const getTaskbyId = async (taskId) => {
  try {
    const response = await http.get(`/tasks/${taskId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getQuestionTask = async (params) => {
  try {
    const response = await http.get(`/task-questions`, { params })
    return response.data
  } catch (error) {
    throw error
  }
}
import React, { useEffect, useState } from 'react'
import LoadingData from '../../../../components/ui/LoadingData'
import ProtectedRoute from '../../../../middleware/ProtectedRoute'
import LayoutProgramDetail from '../../../../components/pages/Member/Program/LayoutProgramDetail'
import { getDetailUserProgram, getRecordedProgram } from '../../../../services/userPrograms'
import { getTutorSchedule } from '../../../../services/tutor'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { update } from '../../../../store/memberProgram'


const DetailProgram = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const programId = params.id
  const [ detail, setDetail ] = useState({})
  const [tutorProgram, setTutorProgram] = useState([])
  const [recordingProgram, setRecordingProgram] = useState([])
  const [ loading, setLoading ] = useState(true)

  const handleGetDetailprogram = async () => {
    setLoading(true)
    try {
      const response = await getDetailUserProgram(programId)
      setDetail(response)
      dispatch(update({ 'data': response }))
      const getTutorProgram = await handleGetTutorProgram(response.eventSchedule.id)
      setTutorProgram(getTutorProgram)
      const getRecordingProgram = await handleGetRecordingProgram(programId)
      setRecordingProgram(getRecordingProgram)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetRecordingProgram = async (programId) => {
    try {
      const response = await getRecordedProgram(programId)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetTutorProgram = async (scheduleId) => {
    try {
      const response = await getTutorSchedule(scheduleId)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetDetailprogram()
    // eslint-disable-next-line
  }, [])

  return (
    <ProtectedRoute>
      {loading ? (<LoadingData />) : (<LayoutProgramDetail detail={detail} videoProgram={recordingProgram} tutor={tutorProgram} handleReload={handleGetDetailprogram} />)}
    </ProtectedRoute>
  )
}

export default DetailProgram
import { Box } from '@mantine/core'
import React from 'react'
import PublicHeader from '../components/ui/PublicHeader'
import PublicFooter from '../components/ui/PublicFooter'

const PublicLayout = ({ children }) => {
  return (
    <Box>
      <PublicHeader />
      <Box py={40}>
        {children}
      </Box>
      <PublicFooter />
    </Box>
  )
}

export default PublicLayout
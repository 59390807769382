import React, { useState, useEffect } from 'react'
import { Box, Loader, Text, Center, Button, List, Flex, Radio, ScrollArea, Textarea } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { createUserAnswer } from '../../../../services/userAnswer'
import { createAnswerAttempts, generateAnswerAttemptsScore, getOneAnswerAttempts } from '../../../../services/answerAttempts'
import { getDetailTask, getDetailUserProgram } from '../../../../services/userPrograms'
import { getQuestionTask } from '../../../../services/tasks'
import mySwal from '../../../../plugins/swallAlert'
import { notificationError } from '../../../../components/ui/Notification'
import { validation } from '../../../../plugins/validation'
import { useDispatch } from 'react-redux'
import { update } from '../../../../store/memberProgram'

const defaultVal = {
  text: ''
}

const formValidation = {
  text: {
    isError: false,
    message: ''
  }
}

const LoadingQuestion = () => {
  return (
    <Box pos="relative"> 
      <Center mih={500} mx="auto">
        <Loader size="xl" />
      </Center>
    </Box>
  )
}

const TaskProgram = ({ detail }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const programId = params.id
  const [detailTask, setDetailTask] = useState(null)
  const [loadingTask, setLoadingTask] = useState(true)
  const [startExam, setStartExam] = useState(false)
  const [indexQuestion, setIndexQuestion] = useState(0)
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [answerAttemptId, setAnswerAttemptId] = useState(null)
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingExam, setLoadingExam] = useState(false)
  const [loadingNextTask, setLoadingNextTask] = useState(false)
  const [showFeedbackTask, setShowFeedbackTask] = useState(false)
  const [dataFeedback, setDataFeedback] = useState([])
  const [taskQuestions, setTaskQuestion] = useState([])

  const handleDetailProgram = async () => {
    try {
      const response = await getDetailUserProgram(programId)
      dispatch(update({ 'data': response }))
    } catch (error) {
      console.log(error)  
    }
  }

  const handleDetailTask = async (taskId) => {
    setLoadingTask(true)
    try {
      const response = await getDetailTask(programId, taskId)
      setDetailTask(response)
    } catch(error) {
      console.log(error)
    } finally {
      setLoadingTask(false)
    }
  }

  const handleStartExam = async (idTask) => {
    setLoadingExam(true)
    const payload = {
      taskId: idTask,
      userEventId: programId
    }
    try {
      const response = await createAnswerAttempts(payload)
      if (response) {
        setAnswerAttemptId(response.id)
        handleQuestionExam(idTask)
      }
    } catch (error) {
      setLoadingExam(false)
      const errorMessage = error.response.data.message
      if (errorMessage === 'maximum attempt reached') {
        mySwal.fire({
          icon: 'error',
          title: 'Gagal Memulai Task',
          text: 'Anda sudah melebihi batas pengerjaan task ini',
          timer: 1500
        })
      } else {
        notificationError(`${errorMessage}`)
      }
    }
  }

  const handleQuestionExam = async (taskId) => {
    const params = {
      task: taskId
    }
    try {
      const response = await getQuestionTask(params)
      if (response) {
        setTaskQuestion(response.data)
        setStartExam(true)
        setLoadingExam(false)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(`${errorMessage}`)
      setStartExam(false)
      setLoadingExam(false)
    }
  }

  const generateScoreQuestion = async (answerAttemptId) => {
    try {
      const response = await generateAnswerAttemptsScore(answerAttemptId)
      if (response) {
        setLoadingNextTask(false)
        mySwal.fire({
          icon: `${response.score > response.task.passingScore ? 'success': 'error'}`,
          title: `${response.score > response.task.passingScore ? 'Selamat': 'Maaf' }`,
          text: `${response.score > response.task.passingScore ? 'Anda lulus dari task ini': 'Anda belum lulus dari task ini'}`
        })
        const checkFeedback = response.task.showFeedback
        if (checkFeedback) {
          setShowFeedbackTask(response.score <= response.task.passingScore ? false : true)
          setStartExam(false)
          setIndexQuestion(0)
          getDataAnswerAttempt(response.curriculumContent.id, response.id)
          handleDetailProgram()
        } else {
          setShowFeedbackTask(false)
          setStartExam(false)
          handleDetailProgram()
          setIndexQuestion(0)
        }
      }
    } catch (error) {
      console.log(error)
      const errorMessage = error.response.data.message
      notificationError('Gagal Submit Task', `${errorMessage}`)
      setLoadingNextTask(false)
    }
  }

  const getDataAnswerAttempt = async (contentId, answerAttemptId) => {
    try {
      const response = await getOneAnswerAttempts(contentId, answerAttemptId)
      setDataFeedback(response.task.taskQuestions)
    } catch(error) {
      console.log(error)
    }
  }

  // const handleFinishedProgress = async(contentId) => {
  //   setLoadingTask(true)
  //   const payload = {
  //     status: 'passed'
  //   }
  //   try {
  //     await finishedContentProgress(programId, contentId, payload)
  //     await handleDetailProgram()
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setLoadingTask(false)
  //   }
  // }

  useEffect(() => {
    if (detail !== null) {
      handleDetailTask(detail.id)
    }
  //eslint-disable-next-line
  }, [detail])

  const nextStep = async (index, taskQuestions) => {
    setLoadingNextTask(true)
    const typeQuestion = taskQuestions[indexQuestion].taskAnswers[0].text
    let payload = {}
    if (typeQuestion !== 'default') {
      payload = {
        answerAttemptId: answerAttemptId,
        taskAnswerId: selectedAnswer
      }
    } else {
      setValidationForm(formValidation)
      payload = {
        answerAttemptId: answerAttemptId,
        taskAnswerId: taskQuestions[indexQuestion].taskAnswers[0].id,
        text: formData.text
      }
      const isError = validation(formData, setValidationForm)
      if (isError) {
        setLoadingNextTask(false)
        return
      }
    }
    try {
      const response = await createUserAnswer(payload)
      if (response) {
        setFormData(defaultVal)
        if (indexQuestion === taskQuestions.length - 1) {
          generateScoreQuestion(answerAttemptId)
        } else {
          const next = index++
          console.log(next)
          setIndexQuestion(index++)
          setLoadingNextTask(false)
        }
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Gagal Submit Task', `${errorMessage === 'taskAnswerId should not be empty' ? 'Silahkan cek jawaban anda' : errorMessage}`)
      console.log(error)
      setLoadingNextTask(false)
    }
  }

  const prevStep = (index) => {
    const prev = index--
    console.log(prev)
    setIndexQuestion(index--)
  }

  const handleChangeForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const initialTask = (detail) => {
    if (detail !== null) {
      return (
        <Box mih={500} pos='relative'>
          <Box mb="xl">
            <Text fz={20} fw="bold">{detail.name}</Text>
            <Text color="dimmed">Kode Soal: <Text component="span" fw="bold">{detail.code}</Text></Text>
          </Box>
          <Box>
            <Text mb={16}>{detail.description}</Text>
            <Box ml={10}>
              <List>
                <List.Item>Tipe: {detail.isChoice ? 'Pilihan Ganda' : 'Isian'}</List.Item>
                <List.Item>Jumlah Soal: {detail.totalQuestion}</List.Item>
                <List.Item>Nilai Lulus: {detail.passingScore}/100</List.Item>
                <List.Item>Waktu Pengerjaan: {detail.timeLimit}</List.Item>
              </List>
            </Box>
          </Box>
          <Box style={{ position: 'absolute', top: '30rem', left: '0', right: '0' }}>
            <Flex justify="space-between" align="center">
              <Text>Nilai Anda : <Text component='span' fw="bold">{detail.answerAttempts.length > 0 ? detail.answerAttempts[0].score : '-'}</Text></Text>
              <Button color="green" loading={loadingExam} onClick={() => handleStartExam(detail.id)}>Mulai Quiz</Button>
            </Flex>
          </Box>
        </Box>
      )
    }
  }

  const questionTasks = (dataQuestions) => {
    return (
      <Box mih={500} pos='relative'>
        <Box mb="xl">
          <Box>
            <ScrollArea h={400} offsetScrollbars scrollbarSize={6}>
              {mappingQuestion(dataQuestions[indexQuestion])}
            </ScrollArea>
            <Box style={{ position: 'absolute', top: '30rem', left: '0', right: '0' }}>
              <Flex justify="space-between" align="center" >
                <Button disabled={indexQuestion === 0} onClick={() => prevStep(indexQuestion)}>Sebelumnya</Button>
                <Button loading={loadingNextTask} onClick={() => nextStep(indexQuestion, dataQuestions)}>{ indexQuestion === dataQuestions.length - 1 ? 'Selesai' : 'Selanjutnya' }</Button>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const mappingQuestion = (dataTask) => {
    const innerHtml = { __html: dataTask.text }
    const typeQuestion = dataTask.taskAnswers[0].text
    if (typeQuestion !== 'default') {
      return (
        <Radio.Group name="questionAnswers" label={dataTask.text ? (<Box dangerouslySetInnerHTML={innerHtml} />) : '-'}>
          {mappingAnswers(dataTask.taskAnswers)}
        </Radio.Group>
      )
    } else {
      return (
        <Box>
          <Box dangerouslySetInnerHTML={innerHtml} />
          <Textarea
            name="text"
            value={formData.text}
            placeholder="Isi Jawabanmu"
            label="Jawaban"
            error={validationForm.text.isError ? `${validationForm.text.message}` : ''}
            autosize
            minRows={3}
            maxRows={3}
            withAsterisk
            onChange={handleChangeForm}
          />
        </Box>
      )
    }
  }

  const mappingAnswers = (dataArray) => dataArray.map((val) => {
    return (
      <Box my={10} key={val.id}>
        <Radio value={val.id} label={val.text} onClick={() => setSelectedAnswer(val.id)} />
      </Box>
    )
  })

  const feedbackPage = (feedbackTask) => {
    if (feedbackTask.length > 0) {
      const findUserAnswer = (userAnswer) => {
        const findAnswer = userAnswer.find((val) => val.isUserAnswer === true)
        return (
          <Box>
            <Text fz={12} fw="bold" c={findAnswer.isCorrect ? 'green.5' : 'red.5'}>{findAnswer.text}</Text>
          </Box>
        )
      }

      const mappingData = feedbackTask.map((val, index) => {
        const innerHtml = { __html: val.text }
        return (
          <Box key={index}>
            <Flex align="start" mb={12}>
              <Text mt={16} mr={6}>{index + 1}. </Text>
              <Box>
                <Box dangerouslySetInnerHTML={innerHtml} />
                <Box mb={12}>
                  <Text fz={12} fw="600"> Jawaban Anda: </Text>
                  {findUserAnswer(val.taskAnswers)}
                </Box>
                <Box>
                  <Text fz={12} fw="500"> Feedback: </Text>
                  <Text fz={12} >{val.feedback}</Text>
                </Box>
                
              </Box>
            </Flex>
          </Box>
        )
      })

      const returnToStart = () => {
        setShowFeedbackTask(false)
        setStartExam(false)
        setIndexQuestion(0)
      }

      return (
        <Box mih={500} pos='relative'>
          <Box mb="xl">
            <Box>
              <ScrollArea h={400} offsetScrollbars scrollbarSize={6}>
                <Text fz={20} fw="bold">Feedback Task</Text>
                {mappingData}
              </ScrollArea>
              <Box style={{ position: 'absolute', top: '30rem', left: '0', right: '0' }}>
                <Flex justify="flex-end" align="center" >
                  <Button onClick={() => returnToStart()}>Kembali Ke Awal</Button>
                </Flex>
              </Box>
            </Box>
            
          </Box>
        </Box>
      )
    }
  }


  return (
    <Box>
      {loadingTask ? (<Box mih={500} pos='relative'><LoadingQuestion /></Box>) : !showFeedbackTask ? !startExam ? initialTask(detailTask) : questionTasks(taskQuestions) : feedbackPage(dataFeedback)}
    </Box>
  )
}

export default TaskProgram
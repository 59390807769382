import http from "../plugins/axios"

// user event
export const getUserProgramList = async (params) => {
  try {
    const response = await http.get(`/participant/user-events`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

// detail user event
export const getDetailUserProgram = async (eventId) => {
  try {
    const response = await http.get(`/participant/user-events/${eventId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

// get user event content
export const getDetailContent = async (trainingId, curriculumContentId) => {
  try {
    const response = await http.get(`/participant/user-events/${trainingId}/contents/${curriculumContentId}`)
    return response.data
  } catch (error) {
    throw error
  }
}
// get user event content
export const getDetailTask = async (trainingId, taskId) => {
  try {
    const response = await http.get(`/participant/user-events/${trainingId}/tasks/${taskId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

// get user event content
export const finishedContentProgress = async (trainingId, contentId, payload) => {
  try {
    const response = await http.post(`/participant/user-events/${trainingId}/contents/${contentId}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

// update feedback member
export const submitFeedbackUserProgram = async (completionId, payload) => {
  try {
    const response = await http.patch(`/user-event-completions/${completionId}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getRecordedProgram = async (trainingId) => {
  try {
    const response = await http.get(`/participant/user-events/${trainingId}/recordings`)
    return response.data
  } catch (error) {
    throw error
  }
}


import React from 'react'
import { Box, Flex, Group, Button, Text } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'

const DetailCertificate = ({ detailData, onCloseModal }) => {
  const openUrl = () => {
    // eslint-disable-next-line
    window.open(`${process.env.REACT_APP_SERVER_API_URL}/files/storage/` + `${detailData.file}`, '_blank')
  }
  if (detailData !== null) {
    return (
      <Box>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400}>Kode Sertifikat:</Text>
          <Text fz="sm" fw="bold">{detailData.code}</Text>
        </Group>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400}>Nama Peserta:</Text>
          <Text fz="sm" fw="bold" tt='capitalize'>{detailData.userEventCompletion.userEvent.user.fullName}</Text>
        </Group>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400} tt='capitalize'>Nama {detailData.userEventCompletion.userEvent.eventSchedule.event.kind}:</Text>
          <Text fz="sm" fw="bold">{detailData.userEventCompletion.userEvent.eventSchedule.event.name}</Text>
        </Group>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400}>Jadwal Pelatihan:</Text>
          <Text fz="sm" fw="bold" tt='capitalize'>{detailData.userEventCompletion.userEvent.eventSchedule.name}</Text>
        </Group>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400}>Tipe Pelatihan:</Text>
          <Text fz="sm" fw="bold" tt='capitalize'>{detailData.userEventCompletion.userEvent.eventSchedule.event.type}</Text>
        </Group>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400}>Tanggal Diterbitkan:</Text>
          <Text fz="sm" fw="bold" tt='capitalize'>{detailData.issuedDate}</Text>
        </Group>
        <Group justify='space-between' align='center' mb={8}>
          <Text fz="sm" weight={400}>Tanggal Kedaluarsa:</Text>
          <Text fz="sm" fw="bold" tt='capitalize'>{detailData.expirationDate}</Text>
        </Group>
        <Box mt={20}>
          <Flex justify='flex-end'>
            <Group>
              <Button size="xs" leftSection={<IconDownload size="1rem" />} color='indigo.9' onClick={() => openUrl()}>Download Sertifikat</Button>
              <Button size='xs' variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            </Group>
          </Flex>
        </Box>
      </Box>
    )
  }
  
}

export default DetailCertificate
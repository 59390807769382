import React from 'react'
import { Accordion, Box, Container, Grid, Text, List } from '@mantine/core'
import classes from '../../../pages/Home/Home.module.css'

const IntroSection = () => {
  return (
    <Box className={classes.customSection}>
      <Container fluid className={classes.section}>
        <Box className={classes.paddingPage}>
          <Grid align='start' gutter='xl'>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Accordion variant='separated'>
                <Accordion.Item value='Acceleration' style={{cursor: 'pointer', border: '0', borderRadius: '10px', zIndex: '1'}}>
                  <Accordion.Control>
                    <Box h={120} py={8} px={10}>
                      <Text className={classes.titleIntro} mb={8}>Acceleration</Text>
                      <Text fz={12} fw={700}>Mempercepat perkembangan kompetensi individu di dunia kerja</Text>
                    </Box>
                  </Accordion.Control>
                  <Accordion.Panel style={{ zIndex:'0', backgroundColor: '#F3901F', border: '0', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                    <Box h={90} my={20} pr={20}>
                      <Text fz={12} fw={700}>Telah melakukan pelatihan untuk lebih dari 500 Alumni pelatihan profesional</Text>
                    </Box>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Accordion variant='separated'>
                <Accordion.Item value='CapacityBuilding' style={{cursor: 'pointer', border: '0', borderRadius: '10px', zIndex: '1'}}>
                  <Accordion.Control>
                    <Box h={120} py={8} px={10}>
                      <Text className={classes.titleIntro} mb={8}>Capacity Building</Text>
                      <Text fz={12} fw={700}>Meningkatkan kemampuan individu dengan modul terkini dan tutor berkompeten</Text>
                    </Box>
                  </Accordion.Control>
                  <Accordion.Panel style={{ zIndex:'0', backgroundColor: '#F3901F', border: '0', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                    <Box h={90} my={20} pr={20}>
                      <Text fz={12} fw={700}>Menggaet lebih dari 20 Tutor dari kalangan akademisi, praktisi dan professional yang telah tersertifikasi dibidangnya</Text>
                    </Box>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Accordion variant='separated'>
                <Accordion.Item value='Professional' style={{cursor: 'pointer', border: '0', borderRadius: '10px', zIndex: '1'}}>
                  <Accordion.Control>
                    <Box h={120} py={8} px={10}>
                      <Text className={classes.titleIntro} mb={8}>Professional</Text>
                      <Text fz={12} fw={700}>Memberikan Pelatihan yang professional dan sesuai dengan kebutuhan perusahaan</Text>
                    </Box>
                  </Accordion.Control>
                  <Accordion.Panel style={{ zIndex:'0', backgroundColor: '#F3901F', border: '0', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                    <Box h={90} my={20} pr={20}>
                      <Text fz={12} fw={700}>Berhasil membantu lebih dari 50 Alumni lulus sertifikasi BNSP (Badan Nasional Sertifikasi Profesi)</Text>
                    </Box>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Accordion variant='separated'>
                <Accordion.Item value='Collaborative' style={{cursor: 'pointer', border: '0', borderRadius: '10px', zIndex: '1'}}>
                  <Accordion.Control>
                    <Box h={120} py={8} px={10}>
                      <Text className={classes.titleIntro} mb={8}>Collaborative</Text>
                      <Text fz={12} fw={700}>Berkerjasama dengan berbagai perusahaan dalam berbagai program</Text>
                    </Box>
                  </Accordion.Control>
                  <Accordion.Panel style={{ zIndex:'0', backgroundColor: '#F3901F', border: '0', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                    <Box h={90} my={20} pr={20}>
                      <List >
                        <List.Item><Text fz={12} fw={700}>Telah melakukan In House Training kepada puluhan Industri dan BUMN</Text></List.Item>
                        <List.Item><Text fz={12} fw={700}>Menjadi penghubung untuk banyak Alumni dengan Industri</Text></List.Item>
                      </List>
                    </Box>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default IntroSection
import React, { useEffect, useState } from 'react'
import { Box, Accordion, Group, Divider, Flex, Text, Select } from '@mantine/core'
import { IconShoppingCart } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { getProgrambySlug } from '../../../services/program'
import { useDispatch } from 'react-redux'
import { update } from '../../../store/cartData'
import { formatPrice } from '../../../plugins/formatPrice'
import classes from '../../../pages/Checkout/Checkout.module.css'

const defaultVal = {
  name: '',
  description: '',
  type: '',
  eventSchedules: [],
  curriculums: [],
  eventImages: [],
  tutors: []
}

const CheckoutSession = () => {
  const dispatch = useDispatch()
  const [detailProgram, setDetailProgram] = useState(defaultVal)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const cartItems = useSelector(state => state.checkout.cart)

  const handleGetDetailProgram = async (slug) => {
    try {
      const response = await getProgrambySlug(slug)
      setDetailProgram(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetDetailProgram(cartItems.eventSlug)
    setSelectedSchedule(cartItems.eventScheduleIds[0])
    // eslint-disable-next-line
  }, [])

  const mappingSchedules = (schedules) => schedules.map((val) => {
    const remap = {
      value: val.id,
      label: val.name
    }
    return remap
  })

  const selectSchedulePrice = (schedulesId) => {
    const dataSchedule = detailProgram.eventSchedules
    const findEvent = dataSchedule.find((val) => val.id === schedulesId)
    return findEvent.price
  }

  const handleSelect = (e) => {
    setSelectedSchedule(e)
    const eventPrice = selectSchedulePrice(e)
    const data = {
      transactionMethodId: cartItems.transactionMethodId,
      eventScheduleIds: [e],
      eventSlug: cartItems.eventSlug,
      totalPrice: eventPrice
    }
    dispatch(update({ 'cart': data }))
  }

  return (
    <Box mb="xl">
      <Accordion variant="contained" defaultValue="checkoutList">
        <Accordion.Item value="checkoutList">
          <Accordion.Control>
            <Group noWrap>
              <IconShoppingCart color="blue" size="2.5rem" />
              <Box>
                <Text className={classes.titleSection} fw="bold">Produk yang dibeli</Text>
                <Text className={classes.captionSection} c="gray.5" weight={400}>Berikut produk yang akan ada dalam transaksi anda</Text>
              </Box>
            </Group>
          </Accordion.Control>
          <Accordion.Panel p={8}>
            <Divider mb="sm" />
            <Flex justify="space-between" align="center" mt={20}>
              <Group noWrap>
                <Box>
                  <Text className={classes.titleSection} fw="bold">{detailProgram.name}</Text>
                  <Text className={classes.captionSection} c='gray.5' weight={400} mb={6}>{detailProgram.description}</Text>
                  <Text className={classes.captionSection} weight={400}>Harga: <Text component="span" className={classes.captionSection} fw="bold">{formatPrice(cartItems.previousPrice !== undefined ? cartItems.previousPrice : cartItems.totalPrice)}</Text></Text>
                </Box>
              </Group>
              <Select 
                label="Jadwal yang dipilih"
                size="xs"
                data={mappingSchedules(detailProgram.eventSchedules)}
                value={selectedSchedule}
                onChange={handleSelect}
                withAsterisk
              />
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}

export default CheckoutSession
import React from 'react'
import classes from './DetailSlug.module.css'
import { Box, Text } from '@mantine/core'

const DetailSection = ({ title, desc, type }) => {
  const innerDescription ={ __html:desc }
  return (
    <Box mb={35}>
      <Text className={classes.titleHeader} mb={10}>{title}</Text>
      <Text fz="xl" mb={10} c="red" tt="capitalize">{type}</Text>
      <Box>
        <Text fw="bold">Deskripsi</Text>
        <Text fz={14}>{desc ? (<Box dangerouslySetInnerHTML={innerDescription} />) : '-'}</Text>
      </Box>
    </Box>
  )
}

export default DetailSection
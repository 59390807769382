import http from "../plugins/axios"

// countries
export const getCountries = async (params) => {
  try {
    const response = await http.get(`/countries`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

// province
export const getProvinces = async (params) => {
  try {
    const response = await http.get(`/provinces`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

// cities
export const getCities = async (params) => {
  try {
    const response = await http.get(`/cities`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}
import React, { useEffect, useState } from 'react'
import { Box, Accordion, Group, Text, Divider, Flex } from '@mantine/core'
import { IconFileInvoice } from '@tabler/icons-react'
import { getDetailTransactionMethod } from '../../../services/transaction'
import { useSelector } from 'react-redux'
import { formatPrice } from '../../../plugins/formatPrice' 
import classes from '../../../pages/Checkout/Checkout.module.css'

const DetailPaymentSession = () => {
  const cartItems = useSelector(state => state.checkout.cart)
  const [transactionMethod, setTransactionMethod] = useState(null)

  const handleDetailTransactionMethod = async (transactionId) => {
    try {
      const response = await getDetailTransactionMethod(transactionId)
      setTransactionMethod(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (cartItems.transactionMethodId !== null) {
      handleDetailTransactionMethod(cartItems.transactionMethodId)
    }
    //eslint-disable-next-line
  }, [cartItems])

  const mappingTotalDiscount = (cartItems) => {
    if (cartItems.typeVoucher !== undefined) {
      return (
        <Box mt={15}>
          <Flex justify="space-between" align="center">
            <Text fz="md" weight={400}>Total Diskon</Text>
            <Text fz="md" fw="bold">{cartItems.typeVoucher === 'total' ? formatPrice(cartItems.totalVoucher) : `${cartItems.totalVoucher} %`}</Text>
          </Flex>
        </Box>
      )
    }
  }

  const mappingPreviousPrice = (cartItems) => {
    if (cartItems.previousPrice !== undefined) {
      return (
        <Box mt={15}>
          <Flex justify="space-between" align="center">
            <Text fz="md" weight={400}>Harga Sebelumnya</Text>
            <Text fz="md" fw="bold" c="red" td='line-through'>{formatPrice(cartItems.previousPrice)}</Text>
          </Flex>
        </Box>
      )
    }
  }

  return (
    <Box mb={70}>
      <Accordion variant="contained" defaultValue="transaction">
        <Accordion.Item value="transaction">
          <Accordion.Control>
            <Group noWrap>
              <IconFileInvoice color="blue" size="2.5rem" />
              <Box>
                <Text className={classes.titleSection} fw="bold">Ringkasan Transaksi</Text>
                <Text className={classes.captionSection} c="gray.5" weight={400}>Pastikan transaksi anda benar</Text>
              </Box>
            </Group>
          </Accordion.Control>
          <Accordion.Panel p={8}>
            <Divider mb="sm" />
            <Box mt={20}>
              <Flex justify="space-between" align="center">
                <Text fz="md" weight={400}>Metode Pembayaran</Text>
                <Text fz="md" fw="bold">{transactionMethod !== null ? transactionMethod.name : '-'}</Text>
              </Flex>
            </Box>
            {mappingTotalDiscount(cartItems)}
            {mappingPreviousPrice(cartItems)}
            <Box mt={15}>
              <Flex justify="space-between" align="center">
                <Text fz="md" weight={400}>Total Pembayaran</Text>
                <Text fz="md" fw="bold">{formatPrice(cartItems.totalPrice)}</Text>
              </Flex>
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}

export default DetailPaymentSession
import React from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import { Box } from '@mantine/core'
import BlogHeader from '../../components/pages/Blog/BlogHeader'
import RecentBlogSection from '../../components/pages/Blog/RecentBlogSection'
import AllBlogSection from '../../components/pages/Blog/AllBlogSection'

const Blog = () => {
  return (
    <PublicLayout>
      <BlogHeader />
      <Box>
        <RecentBlogSection />
        <AllBlogSection />
      </Box>
    </PublicLayout>
  )
}

export default Blog
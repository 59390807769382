import React, { useState } from 'react'
import { Box, Center, Card, Text, TextInput, Group, Button, Modal } from '@mantine/core'
import PublicLayout from '../../layouts/PublicLayout'
import { useViewportSize } from '@mantine/hooks'
import classes from './ValidateCertificate.module.css'
import LajuReaksiLogo from '../../assets/img/LajuReaksiLight.svg'
import { getCertificateByCode } from '../../services/certificate'
import mySwal from '../../plugins/swallAlert'
import { validation } from '../../plugins/validation'
import DetailCertificate from './DetailCertificate'

const defaultVal = {
  code: ''
}

const formValidation = {
  code: {
    isError: false,
    message: ''
  }
}


const ValidateCertificate = () => {
  const { width, height } = useViewportSize()
  const [ formData, setFormData ] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [ loading, setLoading ] = useState(false)
  const [ modalCertificate, setModalCertificate ] = useState(false)
  const [ detailCertificate, setDetailCertificate ] = useState(null)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const validateCode = async (form) => {
    setLoading(true)
    setValidationForm(formValidation)
    const isError = validation(form, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    const code = form.code.replaceAll('/', '-')
    try {
      const response = await getCertificateByCode(code)
      if (response) {
        mySwal.fire({
          icon: 'success',
          title: 'Selamat!',
          text: 'Kode Sertifikat Anda Telah Terverifikasi',
          timer: 1500
        })
        setTimeout(() => {
          setModalCertificate(true)
          setDetailCertificate(response.data)
        }, 1500)
      }
    } catch (error) {
      console.log(error)
      mySwal.fire({
        icon: 'error',
        title: 'Gagal Terverifikasi',
        text: 'Sertifikat anda tidak terverifikasi di Laju Reaksi',
        timer: 1500
      })
    } finally {
      setLoading(false)
    }
  }

  const onCloseModalCertificate = () => {
    setModalCertificate(false)
    setDetailCertificate(null)
  }


  return (
    <PublicLayout>
      <Box className={classes.validatePage} style={{ minHeight: `${height / 2}px`, minWidth: `${width / 1.2}px`  }}>
        <Center maw={width / 1.2} h={height / 1.8} mx='auto'>
          <Card className={classes.validateFormLayout} shadow='sm' radius='md' withBorder>
            <Box>
              <Box ta='center' mb={30}>
                <img src={LajuReaksiLogo} width='160' alt='logo-katalis' />
                <Text fz='xl' fw='bold' mt={10}>Validasi Sertifikat</Text>
                <Text fz='sm' c='gray.6'>Silahkan masukkan kode sertifikat anda</Text>
              </Box>
              <Box mb={30}>
                <Box mb='md'>
                  <TextInput
                    name='code'
                    label='Kode Sertifikat'
                    placeholder='Masukkan Kode Sertifikat'
                    error={validationForm.code.isError ? `${validationForm.code.message}` : ''}
                    onChange={handleChange}
                    withAsterisk
                  />
                </Box>
              </Box>
              <Box>
                <Group grow >
                  <Button radius='md' size='md' loading={loading} color='indigo.9' onClick={() => validateCode(formData)}>Validasi</Button>
                </Group>
              </Box>
            </Box>
          </Card>
        </Center>
      </Box>
      <Modal opened={modalCertificate} onClose={onCloseModalCertificate} centered closeOnClickOutside={false} size='xl' title={<Text fw='Bold'>Detail Sertifikat</Text>} withCloseButton={false}>
        <DetailCertificate detailData={detailCertificate} onCloseModal={onCloseModalCertificate} />
      </Modal>
    </PublicLayout>
    
  )
}

export default ValidateCertificate
import React, { useState, useEffect } from 'react'
import { getUserProgramList } from '../../../../services/userPrograms'
import { Box, Group, Paper, SimpleGrid, Text, Skeleton } from '@mantine/core'
import classes from '../../../../pages/Member/MemberPage.module.css'
import { IconBook, IconFileCheck, IconHourglass, IconBookOff } from '@tabler/icons-react'

const dataStatistic = {
  title: 'Statistik Member',
  total: 0,
  stats: [
    {
      value: 0,
      label: "Selesai",
      icon: IconFileCheck
    },
    {
      value: 0,
      label: "Sedang Berjalan",
      icon: IconHourglass
    },
    {
      value: 0,
      label: "Belum Dimulai", 
      icon: IconBookOff
    },
  ]
}


const StatisticMember = () => {
  const [ statisticData, setDataStatistic ] = useState(dataStatistic)
  const [ loading, isLoading ] = useState(true)

  const handleUserProgramList = async () => {
    isLoading(true)
    try {
      const response = await getUserProgramList()
      const dataProgram = response.data
      const completedProgram = dataProgram.filter((val) => val.summary.progressPercentage === 100)
      const progressProgram = dataProgram.filter((val) => val.summary.progressPercentage > 0 && val.summary.progressPercentage !== null && val.summary.progressPercentage < 100) 
      const notStartProgram = dataProgram.filter((val) => val.summary.progressPercentage === 0 || val.summary.progressPercentage === null)
      const statistic = {
        title: "Statistik Member",
        total: response.count,
        stats: [
          {
            value: completedProgram.length,
            label: "Selesai",
            icon: IconFileCheck
          },
          {
            value: progressProgram.length,
            label: "Sedang Berjalan",
            icon: IconHourglass
          },
          {
            value: notStartProgram.length,
            label: "Belum Dimulai",
            icon: IconBookOff
          },
        ]
      }
      setDataStatistic(statistic)
    } catch (error) {
      console.log(error)
    } finally {
      isLoading(false)
    }
  }

  useEffect(() => {
    handleUserProgramList()
    // eslint-disable-next-line
  }, [])

  const loadingData = () => {
    return (
      <SimpleGrid cols={{ base: 1, md: 2, lg: 2}}>
        <Skeleton height={140} radius='md' />
        <Skeleton height={140} radius='md' />
        <Skeleton height={140} radius='md' />
        <Skeleton height={140} radius='md' />
      </SimpleGrid>
    )
  }

  const mappingStatistic = (data) => data.map((val, index) => {
    return (
      <Paper withBorder p='md' radius='md' key={index}>
        <Group justify='space-between'>
          <Text size="sm" c="dimmed">
            {val.label}
          </Text>
          <val.icon size='1.2rem' stroke='1.5' />
        </Group>
        <Text mt={25}>{val.value}</Text>
        <Text fz="xs" c="dimmed" mt={7}>
          Program
        </Text>
      </Paper>
    )
  })

  

  const loadDataStatistic = () => {
    return (
      <SimpleGrid cols={{ base: 1, md: 2, lg: 2}}>
        <Paper withBorder p='md' radius='md'>
          <Group justify='space-between'>
            <Text size="sm" c="dimmed">
              Total Program
            </Text>
            <IconBook size='1.2rem' stroke='1.5' />
          </Group>
          <Text mt={25}>{statisticData.total}</Text>
          <Text fz="xs" c="dimmed" mt={7}>
            Program yang diikuti
          </Text>
        </Paper>
        {mappingStatistic(statisticData.stats)}
      </SimpleGrid>
    )
  }

  return (
    <Box>
      <Text className={classes.titleDashboard} mb={12} fw='bold'>{statisticData.title}</Text>
      {loading ? loadingData() : loadDataStatistic()}
    </Box>
  )
}

export default StatisticMember
import http from "../plugins/axios"

export const getProfileUser = async (userId) => {
  try {
    const response = await http.get(`/profiles/users/${userId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailUser = async (userId) => {
  try {
    const response = await http.get(`/users/${userId}`)
    return response.data
  } catch(error) {
    throw error
  }
}
export const updateUser = async (userId, payload) => {
  try {
    const response = await http.patch(`/users/${userId}`, payload)
    return response
  } catch(error) {
    throw error
  }
}

export const createProfile = async (payload) => {
  try {
    const response = await http.post('/profiles', payload)
    return response.data
  } catch (error) {
    throw (error)
  }
}

export const updateProfilebyUser = async (userId, payload) => {
  try {
    const response = await http.patch(`/profiles/users/${userId}`, payload)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}

export const updatePhotoProfile = async (userId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/users/${userId}/image`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}

export const getUserSummaries = async () => {
  try {
    const response = await http.get(`/summaries/all`)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}
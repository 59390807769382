import axios from "axios";
import Cookies from "universal-cookie"
import { decodeText, encodedText } from '../plugins/encrypt'
import { refreshToken } from '../services/auth'

const cookies = new Cookies()


const generateToken = (session) => {
  let token = ''
  if (session) {
    const decode = decodeText(session)
    token = JSON.parse(decode)
  }
  return token
}

const http = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_API_URL}`,
})

http.interceptors.request.use(
  (config) => {
    const sessionUser = cookies.get('session')
    const getSessionLogin = generateToken(sessionUser)
    if (getSessionLogin) {
      config.headers['Authorization'] = `Bearer ${getSessionLogin.access_token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    if (error.response.data.message === 'Unauthorized') {
      const prevRequest = error.config
      const sessionToken = cookies.get('session')
      const getSessionLogin = generateToken(sessionToken)
      const payload = {
        refresh_token: getSessionLogin.refresh_token
      }
      const response = await refreshToken(payload)
      prevRequest.headers['Authorization'] = `Bearer ${response.access_token}`
      const stringValue = JSON.stringify(response)
      const encodedData = encodedText(stringValue)
      cookies.set('session', encodedData, { path: '/' })
      return http(prevRequest)
    }
    return Promise.reject(error)
  }
)


export default http
export const paymentTutorial = {
  BCA: {
    'Transfer ATM': [
      'Masukkan Kartu Anda.',
      'Masukkan PIN ATM Anda.',
      'Kemudian, pilih Traksaksi Lainnya.',
      'Pilih Transfer dan pilih ke rekening BCA Virtual Account.',
      'Masukkan nomor BCA Virtual Account Anda.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Konfirmasi, apabila telah sesuai, lanjutkan transaksi.',
      'Transaksi Anda telah selesai.'
    ],
    'Transfer Mobile Banking': [
      'Akses BCA Mobile melalui handphone.',
      'Pilih m-BCA.',
      'Masukkan kode akses m-BCA anda.',
      'Pilih menu Transfer.',
      'Pilih menu BCA Virtual Account',
      'Masukkan nomor Virtual Account Anda pada menu Input Baru lalu tekan tombol kirim.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Konfirmasi transaksi dan masukkan Password Transaksi.',
      'Pembayaran Anda Telah Berhasil.'
    ],
    'Transfer Internet Banking': [
      'Akses klikbca.com kemudian klik Enter.',
      'Masukkan User ID dan password.',
      'Klik menu Transfer Dana , lalu pilih BCA Virtual Account.',
      'Kemudian, masukan nomor Virtual Account Anda.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Anda akan menerima notifikasi bahwa transaksi berhasil.'
    ],
    'Transfer via Teller': [
      'Kunjungi Kantor Cabang/Outlet BCA terdekat.',
      'Informasikan kepada Teller, bahwa Anda ingin melakukan pembayaran Virtual Account Billing.',
      'Serahkan nomor Virtual Account Anda kepada Teller.',
      'Teller akan melakukan konfirmasi kepada Anda dan akan memproses Transaksi.',
      'Apabila transaksi Sukses, Anda akan menerima bukti pembayaran dari Teller tersebut.'
    ],
    'Transfer via ATM Bersama': [
      'Masukkan kartu ke mesin ATM Bersama.',
      'Pilih Transaksi Lainnya.',
      'Pilih menu Transfer.',
      'Pilih Transfer ke Bank Lain.',
      'Masukkan kode bank BCA (014) dan 15 Digit Nomor Virtual Account.',
      'Masukkan nominal transfer sesuai tagihan Anda. Nominal yang berbeda tidak dapat diproses.',
      'Konfirmasi rincian Anda akan tampil pada layar.',
      'Jika sudah Sesuai, klik `Ya` untuk Melanjutkan.',
      'Transaksi Anda telah berhasil.'
    ],
    'Transfer OVO': [
      'Buka aplikasi OVO.',
      'Pilih menu Transfer.',
      'Pilih Rekening Bank.',
      'Masukkan kode bank BCA (014) atau pilih bank yang dituju yaitu BCA.',
      'Masukan 15 Digit Nomor Virtual Account pada kolom rekening tujuan',
      'Masukkan nominal transfer sesuai tagihan Anda. Pilih Transfer.',
      'Konfirmasi rincian Anda akan tampil di layar.',
      'Jika sudah Sesuai, klik `Konfirmasi` untuk Melanjutkan.',
      'Transaksi Anda telah berhasil.'
    ]
  },
  BNI: {
    'Transfer ATM': [
      'Masukkan Kartu Anda.',
      'Pilih Bahasa.',
      'Masukkan PIN ATM Anda.',
      'Kemudian, pilih Menu Lainnya.',
      'Pilih Transfer dan pilih Jenis rekening yang akan Anda gunakan (Contoh: "Dari Rekening Tabungan").',
      'Pilih Virtual Account Billing. Masukkan nomor Virtual Account Anda',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Konfirmasi, apabila telah sesuai, lanjutkan transaksi.',
      'Transaksi Anda telah selesai.'
    ],
    'Transfer Mobile Banking': [
      'Akses BNI Mobile Banking melalui handphone.',
      'Masukkan User ID dan password.',
      'Pilih menu Transfer.',
      'Pilih menu Virtual Account Billing',
      'Masukkan nomor Virtual Account Anda pada menu Input Baru lalu tekan tombol kirim.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Konfirmasi transaksi dan masukkan Password Transaksi.',
      'Pembayaran Anda Telah Berhasil.'
    ],
    'Transfer Internet Banking': [
      'Akses ibank.bni.co.id kemudian klik Enter.',
      'Masukkan User ID dan password.',
      'Klik menu Transfer, lalu pilih Virtual Account Billing.',
      'Kemudian, masukan nomor Virtual Account Anda yang akan dibayarkan.',
      'Lalu pilih rekening debet yang akan digunakan. Kemudian tekan Lanjut.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Masukkan Kode Otentikasi Token.',
      'Anda akan menerima notifikasi bahwa transaksi berhasil.'
    ],
    'Transfer SMS Banking': [
      'Buka aplikasi SMS Banking BNI.',
      'Pilih menu Transfer.',
      'Pilih menu Transfer rekening BNI',
      'Masukkan nomor rekening tujuan dengan 16 digit Nomor Virtual Account',
      'Masukkan nominal transfer sesuai tagihan. Nominal yang berbeda tidak dapat diproses.',
      'Pilih Proses, kemudian Setuju.',
      'Balas sms dengan mengetik pin sesuai dengan instruksi BNI. Anda akan menerima notif bahwa transaksi berhasil.',
      'Atau dapat juga langsung mengetik sms dengan format: TRF[SPASI]NomorVA[SPASI]NOMINAL dan kemudian kirim ke 3346. (Contoh: TRF 1234567890123456 44000)'
    ],
    'Transfer via Teller': [
      'Kunjungi Kantor Cabang/Outlet BNI terdekat.',
      'Informasikan kepada Teller, bahwa Anda ingin melakukan pembayaran Virtual Account Billing.',
      'Serahkan nomor Virtual Account Anda kepada Teller.',
      'Teller akan melakukan konfirmasi kepada Anda dan akan memproses Transaksi.',
      'Apabila transaksi Sukses, Anda akan menerima bukti pembayaran dari Teller tersebut.'
    ],
    'Transfer via Agen46': [
      'Kunjungi Agen46 terdekat (warung/took/kios dengan tulisan Agen46).',
      'Informasikan kepada Agen46, bahwa ingin melakukan pembayaran Virtual.',
      'Serahkan nomor Virtual Account Anda kepada Agen46.',
      'Agen46 akan melakukan konfirmasi kepada Anda.',
      'Selanjutnya, transaksi akan diproses.',
      'Apabila transaksi dinyatakan sukses, Anda akan menerima bukti pembayaran dari Agen46.'
    ],
    'Transfer via ATM Bersama': [
      'Masukkan kartu ke mesin ATM Bersama.',
      'Pilih Transaksi Lainnya.',
      'Pilih menu Transfer.',
      'Pilih Transfer ke Bank Lain.',
      'Masukkan kode bank BNI (009) dan 16 Digit Nomor Virtual Account.',
      'Masukkan nominal transfer sesuai tagihan Anda. Nominal yang berbeda tidak dapat diproses.',
      'Konfirmasi rincian Anda akan tampil pada layar.',
      'Jika sudah Sesuai, klik `Ya` untuk Melanjutkan.',
      'Transaksi Anda telah berhasil.'
    ],
    'Transfer OVO': [
      'Buka aplikasi OVO.',
      'Pilih menu Transfer.',
      'Pilih Rekening Bank.',
      'Masukkan kode bank BNI (009) atau pilih bank yang dituju yaitu BNI.',
      'Masukan 15 Digit Nomor Virtual Account pada kolom rekening tujuan',
      'Masukkan nominal transfer sesuai tagihan Anda. Pilih Transfer.',
      'Konfirmasi rincian Anda akan tampil di layar.',
      'Jika sudah Sesuai, klik `Konfirmasi` untuk Melanjutkan.',
      'Transaksi Anda telah berhasil.'
    ]
  },
  BRI: {
    'Transfer ATM': [
      'Masukkan Kartu Anda.',
      'Masukkan PIN ATM Anda.',
      'Kemudian, Pilih menu Transaksi Lain, kemudian pilih menu Pembayaran',
      'Setelah itu klik Menu Lainnya, lalu pilih menu Briva',
      'Masukkan nomor rekening dengan nomor Virtual Account Anda.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Konfirmasi, apabila telah sesuai, lanjutkan transaksi.',
      'Transaksi Anda telah selesai.'
    ],
    'Transfer Mobile Banking': [
      'Silahkan login Mobile Banking, lalu pilih menu Pembayaran.',
      'Setelah itu klik menu Brivia',
      'Masukkan nomor Virtual Account Anda dan jangan lupa tuliskan jumlah nominal tagihan yang ada di invoice.',
      'Lalu masukkan PIN Mobile Banking dan klik Kirim.',
      'Transaksi sudah selesai. Bukti pembayaran anda akan dikirimkan melalui notifikasi SMS.'
    ],
    'Transter Internet Banking': [
      'Silahkan login Internet Banking, kemudian pilih Menu Pembayaran.',
      'Lalu pilih menu Brivia.',
      'Masukkan nomor rekening dengan nomor Virtual Account Anda kemudian klik Kirim.',
      'Setelah itu, masukkan password serta mToken internet banking.'
    ]
  },
  Mandiri: {
    'Transfer ATM': [
      'Masukkan Kartu Anda.',
      'Masukkan PIN ATM Anda.',
      'Kemudian, Pilih menu Transaksi Lain, kemudian pilih menu Pembayaran',
      'Setelah itu klik Menu Lainnya, lalu pilih menu Mandiri',
      'Masukkan nomor rekening dengan nomor Virtual Account Anda.',
      'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.',
      'Konfirmasi, apabila telah sesuai, lanjutkan transaksi.',
      'Transaksi Anda telah selesai.'
    ],
    'Transfer Mobile Banking (Livin)': [
      'Silahkan login Livin, lalu pilih menu Pembayaran.',
      'Pada kolom pencarian ketik "midtrans"',
      'Masukkan nomor Virtual Account Anda dan jangan lupa tuliskan jumlah nominal tagihan yang ada di invoice.',
      'Lalu masukkan PIN Livin dan klik kirim.',
      'Transaksi anda sudah selesai.'
    ],
    'Transter Internet Banking': [
      'Silahkan login Internet Banking, kemudian pilih Menu Pembayaran.',
      'Lalu pilih menu Mandiri.',
      'Masukkan nomor rekening dengan nomor Virtual Account Anda kemudian klik Kirim.',
      'Setelah itu, masukkan password serta mToken internet banking.'
    ]
  },
  Alfamart: {
    'Gerai Alfamart': [
      'Datang ke salah satu gerai Alfamart terdekat.',
      'Lakukan pembayaran ke kasir dengan menyebutkan: KODE PEMBAYARAN.',
      'Bayar sesuai jumlah tagihan kamu (Catatan: belum termasuk biaya Alfamart).',
      'Terima dan simpan struk pembayaran kamu.',
      'Pembayaran selesai.'
    ]
  },
  Indomaret: {
    'Gerai Indomaret': [
      'Datang ke salah satu gerai Indomaret terdekat.',
      'Lakukan pembayaran ke kasir dengan menyebutkan: KODE PEMBAYARAN.',
      'Bayar sesuai jumlah tagihan kamu (Catatan: belum termasuk biaya Indomaret).',
      'Terima dan simpan struk pembayaran kamu.',
      'Pembayaran selesai.'
    ]
  },
  Gopay: {
    'QR Code': [
      'Buka Aplikasi Gojek dan klik "Bayar".',
      'Scan Kode QR',
      'Masukkan nominal transaksi/pastikan nominal transaksi sudah sesuai.',
      'Klik "Konfirmasi & Bayar".',
      'Masukkan PIN GoPay kamu.',
      'Pembayaran berhasil.'
    ]
  },
  QRIS: {
    'QR Code': [
      'Buka Aplikasi M-Banking anda dan klik "Bayar".',
      'Scan Kode QR',
      'Masukkan nominal transaksi/pastikan nominal transaksi sudah sesuai.',
      'Klik "Konfirmasi & Bayar".',
      'Masukkan PIN M-Banking kamu.',
      'Pembayaran berhasil.'
    ]
  },
  ShopeePay: {
    'QR Code': [
      'Buka aplikasi Shopee.',
      'Pilih menu “Saya” yang biasanya terletak di pojok kanan bawah aplikasi.',
      'Pilih menu “ShopeePay”.',
      'Pilih menu “Bayar”.',
      'Arahkan kamera ke kode QRIS yang ada di merchant.',
      'Selanjutnya, layar smartphone akan menampilkan detail transaksi dan pembayaran pun sukses dilakukan.'
    ]
  }
}
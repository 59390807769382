import React from 'react'
import { Table, Badge, ActionIcon, Paper } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

const badgeStatus = {
  pending: {
    label: 'Menunggu Pembayaran',
    color: 'yellow'
  },
  fail: {
    label: 'Gagal',
    color: 'red'
  },
  success: {
    label: 'Transaksi Berhasil',
    color: 'green'
  }
}

const TableTransactionMember = ({ label, data }) => {
  const navigate = useNavigate()

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.invoiceId}</Table.Td>
        <Table.Td><Badge size="xs" radius="sm" variant="filled" color={badgeStatus[val.status].color}>{badgeStatus[val.status].label}</Badge></Table.Td>
        <Table.Td>
          <ActionIcon variant='transparent' onClick={() => navigate(`/transaction/${val.invoiceId}`)}>
            <IconEye />
          </ActionIcon>
        </Table.Td>
      </Table.Tr>
    )
  })


  return (
    <Table.ScrollContainer maxWidth={715}>
      <Paper withBorder radius='md' h={273}>
        <Table striped highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              {mappingTableHead(label)}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {mappingDataTable(data)}
          </Table.Tbody>
        </Table>
      </Paper>
    </Table.ScrollContainer>
  )
}

export default TableTransactionMember
import React from 'react'
import { Box, Text } from '@mantine/core'
import ReactPlayer from 'react-player'
import '../../../../assets/css/player.css'

const RecordingProgram = ({ detailRecording }) => {
  return (
    <Box mih={500} pos='relative'>
      <Text fz={20} fw="bold">Rekaman Video {detailRecording.name}</Text>
      <Box mt={10}>
        <Box className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            url={detailRecording.file}
            // playing={true}
            controls={true}
            // light={true}
            width="100%"
            height="500px"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default RecordingProgram
import React from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import { Box, Container, List, Text } from '@mantine/core'
import classes from './PrivacyPolicy.module.css'

const PrivacyPolicy = () => {
  return (
    <PublicLayout>
      <Box className={classes.policySection}>
        <Container fluid className={classes.section}>
          <Box>
            <Box className={classes.paddingPage}>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>Kebijakan Privasi</Text>
                <Text className={classes.captionPolicy} mb={4}>Perlindungan informasi pribadi merupakan prioritas utama bagi PT. Katalis Talenta Indonesia ("Kami"). Kami bertekad untuk melindungi dan menghormati privasi pengguna ("Anda") saat menggunakan berbagai layanan yang kami sediakan, termasuk aplikasi, situs web (www.lajureaksi.co.id), fitur-fitur, teknologi, konten, dan produk kami secara bersama-sama disebut sebagai "Platform".</Text>
                <Text className={classes.captionPolicy} mb={4}>Kebijakan Privasi ini membentuk dasar bagi penggunaan informasi pribadi yang kami kumpulkan atau yang Anda berikan kepada kami ("Informasi Pribadi"). Kebijakan ini berlaku untuk semua pengguna Platform, kecuali diatur oleh kebijakan privasi terpisah. Kami menyarankan Anda untuk membaca Kebijakan Privasi ini dengan seksama agar Anda dapat memahami pendekatan dan praktik kami dalam penggunaan informasi pribadi.</Text>
                <Text className={classes.captionPolicy} mb={4}>Kebijakan Privasi kami mencakup beberapa hal, antara lain:</Text>
                <List className={classes.captionPolicy} type='ordered' withPadding>
                  <List.Item mb={4}>Jenis Informasi Pribadi yang Kami Kumpulkan</List.Item>
                  <List.Item mb={4}>Penggunaan Informasi Pribadi</List.Item>
                  <List.Item mb={4}>Keamanan dan Kerahasiaan Data</List.Item>
                  <List.Item mb={4}>Penyimpanan Informasi Pribadi</List.Item>
                  <List.Item mb={4}>Hak Anda</List.Item>
                  <List.Item mb={4}>Cara Menghubungi Kami</List.Item>
                </List>
                <Text className={classes.captionPolicy} mb={4}>Dengan mengakses dan/atau mendaftar akun di Platform kami, Anda dianggap telah menerima dan menyetujui pendekatan serta praktik yang dijelaskan dalam Kebijakan Privasi ini.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>JENIS INFORMASI PRIBADI YANG KAMI KUMPULKAN</Text>
                <Text className={classes.captionPolicy} mb={4}>Data Pribadi yang akan kami kumpulkan dan simpan mencakup namun tidak terbatas pada:</Text>
                <List type='ordered'>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionPolicy} mb={6} fw='bold'>Informasi yang Anda berikan.</Text>
                      <Text className={classes.captionPolicy} mb={4}>Anda bisa menyediakan kami informasi melalui formulir elektronik dan melalui aktivitas serta transaksi Anda di Platform. Informasi tersebut mencakup identitas pribadi, nomor telepon, alamat email, detail transaksi pembelian, proses aktivasi voucher, catatan aktivitas, dan informasi lain yang Anda berikan saat menggunakan Platform.</Text>
                    </Box>
                  </List.Item>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionPolicy} mb={6} fw='bold'>Informasi yang kami kumpulkan.</Text>
                      <Text className={classes.captionPolicy} mb={4}>Kami memiliki hak untuk mengumpulkan informasi secara otomatis dari aktivitas dan kunjungan Anda di Platform, yang mencakup:</Text>
                      <List withPadding my={4} className={classes.captionPolicy}>
                        <List.Item mb={4}>Informasi teknis, seperti alamat protokol internet (IP address) yang digunakan untuk menghubungkan komputer Anda ke internet, informasi login Anda, jenis dan versi peramban (browser) yang digunakan, pengaturan zona waktu, jenis dan versi ekstensi peramban (browser plug-in), sistem operasi, dan platform.</List.Item>
                        <List.Item mb={4}>Informasi tentang kunjungan Anda, termasuk daftar lengkap lokasi sumber seragam (uniform resource locators (URL)) yang Anda kunjungi, baik menuju, melalui, maupun dari Platform (termasuk tanggal dan waktu), produk yang Anda lihat atau cari, waktu respon halaman, masalah pengunduhan, durasi kunjungan pada halaman tertentu di Platform, informasi interaksi pada halaman (seperti pengguliran, klik, atau pergerakan mouse), metode yang digunakan untuk meninggalkan Platform, serta nomor telepon yang Anda gunakan untuk menghubungi layanan pelanggan kami.</List.Item>
                        <List.Item mb={4}>Data nilai Anda, termasuk namun tidak terbatas pada hasil ujian yang Anda peroleh melalui Platform.</List.Item>
                      </List>
                    </Box>
                  </List.Item>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionPolicy} mb={6} fw='bold'>Informasi yang kami terima dari sumber lain.</Text>
                      <Text className={classes.captionPolicy} mb={4}>Kami berhak untuk menerima informasi jika Anda menggunakan situs atau aplikasi lain yang kami operasikan, atau layanan lain yang kami sediakan. Kami juga dapat bekerja sama dengan pihak ketiga, termasuk mitra bisnis, sub-kontraktor dalam layanan teknis, penyedia layanan pembayaran dan pengiriman, penyedia layanan analisis situs (dengan mematuhi ketentuan dalam Kebijakan Privasi ini), serta penyedia layanan pencarian informasi ("Mitra Kami"). Kami akan mengambil langkah-langkah yang wajar untuk memverifikasi informasi yang kami terima dari sumber lain sesuai dengan peraturan perundang-undangan yang berlaku.</Text>
                    </Box>
                  </List.Item>
                </List>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>PENGGUNAAN INFORMASI PRIBADI</Text>
                <Text className={classes.captionPolicy} mb={4}>PT Katalis Talenta Indonesia berhak menggunakan Data Pribadi Anda untuk:</Text>
                <List className={classes.captionPolicy} withPadding>
                  <List.Item mb={4}>Memverifikasi Data Pribadi yang terdapat pada akun Anda.</List.Item>
                  <List.Item mb={4}>Menyediakan, atau mengizinkan Mitra Kami (baik yang berada di dalam maupun di luar wilayah Republik Indonesia) untuk menyediakan, informasi, produk, dan jasa kami kepada Anda, sesuai dengan peraturan perundang-undangan yang berlaku.</List.Item>
                  <List.Item mb={4}>Melakukan, atau mengizinkan Mitra Kami (baik yang berada di dalam maupun di luar wilayah Indonesia), melakukan riset untuk meningkatkan kualitas pengalaman dan pelayanan dalam menggunakan Platform, dengan mematuhi peraturan perundang-undangan yang berlaku.</List.Item>
                  <List.Item mb={4}>Menggunakan sebagai sumber informasi dalam melakukan sosialisasi, publikasi, atau promosi terkait produk dan jasa kami.</List.Item>
                  <List.Item mb={4}>Berkomunikasi secara langsung kepada Pengguna Platform.</List.Item>
                  <List.Item mb={4}>Mematuhi setiap peraturan perundang-undangan yang berlaku.</List.Item>
                </List>
                <Text className={classes.captionPolicy} mb={4}>Kami juga dapat menggunakan informasi yang tidak dapat diidentifikasi sebagai Data Pribadi Anda, seperti cookies dan identifikasi perangkat, untuk:</Text>
                <List className={classes.captionPolicy} withPadding>
                  <List.Item mb={4}>Meningkatkan pengalaman Anda dalam menggunakan Platform kami.</List.Item>
                  <List.Item mb={4}>Menyediakan informasi statistik tentang penggunaan Platform.</List.Item>
                  <List.Item mb={4}>Mengadministrasikan layanan pada Platform kami.</List.Item>
                  <List.Item mb={4}>Memecahkan masalah teknis.</List.Item>
                  <List.Item mb={4}>Menganalisis tren penggunaan.</List.Item>
                  <List.Item mb={4}>Mengumpulkan data demografi secara umum.</List.Item>
                  <List.Item mb={4}>Mematuhi ketentuan peraturan perundang-undangan yang berlaku.</List.Item>
                </List>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>KEAMANAN DAN KERAHASIAAN DATA</Text>
                <Text className={classes.captionPolicy} mb={4}>Data Pribadi Anda akan diamankan dan digunakan secara rahasia oleh PT Katalis Talenta Indonesia. Kami tidak akan menjual, mengungkapkan, atau memberikan Data Pribadi Anda kepada pihak ketiga diluar PT Katalis Talenta Indonesia, kecuali dalam situasi berikut:</Text>
                <List className={classes.captionPolicy} withPadding>
                  <List.Item mb={4}>Jika kami memperoleh persetujuan Anda.</List.Item>
                  <List.Item mb={4}>Jika diwajibkan oleh peraturan perundang-undangan yang berlaku, proses hukum, atau permintaan pemerintah yang sah untuk memenuhi kewajiban hukum kami, termasuk investigasi potensi pelanggaran terhadap Ketentuan Penggunaan atau Kebijakan Privasi kami, atau untuk mengatasi kegiatan ilegal, penipuan, masalah keamanan, atau teknis, atau untuk melindungi hak-hak kami.</List.Item>
                </List>
                <Text className={classes.captionPolicy} mb={4}>Kami dapat membagikan Data Pribadi Anda kepada:</Text>
                <List className={classes.captionPolicy} withPadding>
                  <List.Item mb={4}>Penerus, karyawan, penasihat, Mitra Kami, anak perusahaan, afiliasi kami, pengguna lain, atau masyarakat lainnya sesuai dengan peraturan perundang-undangan.</List.Item>
                  <List.Item mb={4}>Mitra Kami atau pihak ketiga di luar PT Katalis Talenta Indonesia yang memiliki kepentingan dalam menjalankan atau meningkatkan layanan dalam Platform kami, dengan syarat mereka menandatangani perjanjian kerahasiaan dengan kami.</List.Item>
                </List>
                <Text className={classes.captionPolicy} mb={4}>Kami akan menggunakan Data Pribadi Anda dengan tingkat kehati-hatian yang wajar untuk menjaga kerahasiaannya. Namun, kewajiban kami untuk menjaga kerahasiaan tidak berlaku jika:</Text>
                <List className={classes.captionPolicy} withPadding>
                  <List.Item mb={4}>Data Pribadi telah diketahui oleh publik sebelumnya.</List.Item>
                  <List.Item mb={4}>Data Pribadi telah diketahui oleh kami sebelumnya.</List.Item>
                  <List.Item mb={4}>Kami memperoleh Data Pribadi secara sah dari pihak ketiga tanpa kewajiban kerahasiaan.</List.Item>
                  <List.Item mb={4}>Data Pribadi telah atau sedang kami kembangkan secara independen.</List.Item>
                  <List.Item mb={4}>Pengungkapan diwajibkan oleh peraturan perundang-undangan yang berlaku.</List.Item>
                </List>
                <Text className={classes.captionPolicy} mb={4}>Anda menyetujui bahwa PT Katalis Talenta Indonesia tidak bertanggung jawab atas pelanggaran keamanan terkait dengan pesan, informasi, atau dokumen yang Anda kirimkan melalui internet. Jika terjadi kegagalan dalam melindungi Data Pribadi di dalam Platform, kami akan memberitahukan Anda secara tertulis melalui media elektronik atau lainnya, atau secara langsung dengan pos terdaftar.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>PENYIMPANAN INFORMASI PRIBADI</Text>
                <Text className={classes.captionPolicy} mb={4}>Segala informasi Pribadi yang Anda berikan kepada kami akan disimpan dengan aman di server kami. Setiap transaksi pembayaran pada Platform akan dienkripsi. Dengan menyerahkan Informasi Pribadi Anda pada Platform, Anda menyetujui pengalihan, penyimpanan, serta pengolahan yang dilakukan oleh kami. Kami akan mengambil langkah-langkah yang sesuai untuk memastikan bahwa Informasi Pribadi tersebut diperlakukan dengan aman dan sesuai dengan Kebijakan Privasi serta peraturan yang berlaku.</Text>
                <Text className={classes.captionPolicy} mb={4}>Informasi Pribadi yang Anda berikan akan disimpan oleh kami:</Text>
                <List className={classes.captionPolicy} withPadding>
                  <List.Item mb={4}>Selama Anda masih menggunakan Platform Kami, dan</List.Item>
                  <List.Item mb={4}>Setidaknya 5 (lima) tahun setelah Anda berhenti menggunakan Platform Kami, atau</List.Item>
                  <List.Item mb={4}>Sesuai dengan tujuan awal pengumpulan Informasi Pribadi tersebut.</List.Item>
                </List>
                <Text className={classes.captionPolicy} mb={4}>Jika kami memberikan (atau Anda memilih) sebuah kata sandi untuk mengakses bagian-bagian tertentu pada Platform Kami, Anda bertanggung jawab untuk menjaga kerahasiaan kata sandi tersebut dan tidak membagikannya kepada siapapun.</Text>
                <Text className={classes.captionPolicy} mb={4}>Harap diperhatikan bahwa transmisi informasi melalui internet tidak sepenuhnya aman. Meskipun demikian, kami akan berusaha semaksimal mungkin untuk melindungi Informasi Pribadi Anda. Kami tidak dapat menjamin keamanan data yang dikirimkan ke Platform Kami, dan risiko dari setiap transmisi menjadi tanggung jawab Anda. Setelah kami menerima Informasi Pribadi Anda, kami akan menggunakan prosedur yang ketat dan fitur keamanan untuk mencegah akses yang tidak diizinkan.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>HAK ANDA</Text>
                <Text className={classes.captionPolicy} mb={4}>Anda memiliki hak untuk meminta penghapusan Informasi Pribadi Anda dari Platform atau menarik persetujuan Anda terhadap pengumpulan, penggunaan, atau pengungkapan Informasi Pribadi Anda dengan memberikan pemberitahuan tertulis kepada kami melalui kontak yang tercantum dalam bagian J Kebijakan Privasi ini. Namun, Anda harus menyadari bahwa penarikan persetujuan atau permohonan penghapusan tersebut dapat mengakibatkan Anda tidak dapat lagi menggunakan Platform. Ini juga dapat mengakibatkan penghentian Akun Anda atau hubungan kontraktual Anda dengan kami, dengan semua hak dan kewajiban yang berlaku harus dipenuhi sepenuhnya. Setelah kami menerima pemberitahuan penarikan persetujuan atau permohonan penghapusan, kami akan memberi tahu Anda tentang konsekuensi yang mungkin timbul, sehingga Anda dapat membuat keputusan yang tepat.</Text>
                <Text className={classes.captionPolicy} mb={4}>Selain itu, Anda dapat meminta akses dan/atau koreksi Informasi Pribadi Anda yang kami miliki dengan menghubungi kami menggunakan detail kontak yang disediakan di bawah ini.</Text>
                <Text className={classes.captionPolicy} mb={4}>Platform kami dapat mengandung link ke situs-situs milik mitra jaringan, pengiklan, dan afiliasi lainnya. Harap diingat bahwa situs-situs tersebut memiliki Kebijakan Privasi mereka sendiri, dan kami tidak bertanggung jawab atas kebijakan atau praktik privasi mereka. Sebelum memberikan informasi apa pun kepada situs-situs tersebut, pastikan untuk memeriksa kebijakan privasi mereka terlebih dahulu.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>PERUBAHAN KEBIJAKAN PRIVASI</Text>
                <Text className={classes.captionPolicy} mb={4}>Kami berhak untuk mengubah, memodifikasi, menambahkan, atau mengamandemen Kebijakan Privasi ini tanpa pemberitahuan terlebih dahulu kepada Pengguna. Setiap perubahan akan ditampilkan pada halaman ini. Dengan tetap menggunakan Platform kami setelah perubahan tersebut, Anda dianggap menyetujui perubahan tersebut. Kami menyarankan Anda untuk secara teratur memeriksa Kebijakan Privasi ini untuk mengetahui perubahan apa pun. Ketika ada perubahan, kami akan memperbarui tanggal "Terakhir diubah" di atas dan memposting Kebijakan Privasi yang diperbarui.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titlePolicy} mb={6}>CARA MENGHUBUNGI KAMI</Text>
                <Text className={classes.captionPolicy} mb={4}>Jika Anda memerlukan klarifikasi tentang Kebijakan Privasi ini atau ingin mengakses atau mengoreksi Informasi Pribadi Anda, silakan hubungi kami melalui kontak yang tercantum di halaman "Tentang". </Text>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </PublicLayout>
  )
}

export default PrivacyPolicy
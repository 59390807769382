import React from 'react'
import { CopyButton, ActionIcon, Tooltip } from '@mantine/core'
import { IconCopy, IconCheck } from '@tabler/icons-react' 

const CopyText = ({ text }) => {
  return (
    <CopyButton value={text} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
          <ActionIcon size='sm'  color={copied ? 'teal.5' : 'gray.5'} onClick={copy}>
            {copied ? <IconCheck size="0.8rem" /> : <IconCopy size="0.8rem" />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  )
}

export default CopyText
import React from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import classes from './TermCondition.module.css'
import { Box, Container, List, Text } from '@mantine/core'

const TermsCondition = () => {
  return (
    <PublicLayout>
      <Box className={classes.termSection}>
        <Container fluid className={classes.section}>
          <Box>
            <Box className={classes.paddingPage}>
              <Box mb={20}>
                <Text className={classes.titleTerm} mb={6}>SYARAT DAN KETENTUAN</Text>
                <Text className={classes.captionTerm}>Selamat datang di Laju Reaksi, platform yang dikelola oleh PT Katalis Talenta Indonesia, beralamat di Eastern Park Residence, Jl. Raya Sukolilo Mulia, Ruko A-05, Keputih, Kec. Sukolilo, Kota SBY, Jawa Timur, 60111, Indonesia.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titleTerm} mb={6}>TENTANG LAJUREAKSI</Text>
                <Text className={classes.captionTerm} mb={4}>Merupakan platform konsultasi, pelatihan, dan sertifikasi kompetensi lingkungan. Dirancang untuk memenuhi kebutuhan profesi dan perusahaan yang berfokus pada tanggung jawab lingkungan, platform ini menawarkan akses mudah ke berbagai sumber daya yang relevan dan interaktif.</Text>
                <Text className={classes.captionTerm}>Berikut merupakan ketentuan penggunaan untuk situs, konten, layanan, dan fitur yang terdapat di Laju Reaksi. Kami sangat menyarankan Anda untuk membaca dengan seksama ketentuan layanan ini. Dengan mengakses dan menggunakan situs web kami, ini menandakan pemahaman dan persetujuan Anda untuk mematuhi semua peraturan yang berlaku pada situs ini.</Text>
              </Box>
              <Box mb={20}>
                <Text className={classes.titleTerm} mb={6}>Penggunaan Situs lajureaksi.co.id</Text>
                <List className={classes.captionTerm} type='ordered'>
                  <List.Item mb={4}>Situs lajureaksi.co.id ("Situs") dan layanan yang disediakan di dalamnya hanya diperuntukkan bagi Pengguna untuk penggunaan pribadi, terutama untuk keperluan pemesanan dan/atau pembayaran.</List.Item>
                  <List.Item mb={4}>Syarat dan Ketentuan ini merupakan bagian integral dari Kebijakan Privasi. Dengan menggunakan Situs ini, Pengguna secara tegas menyetujui untuk tunduk pada ketentuan dalam Syarat dan Ketentuan ini serta Kebijakan Privasi yang tercantum. </List.Item>
                  <List.Item mb={4}>Hak atas Situs ini, termasuk produk-produk, teknologi, dan proses yang ada di dalamnya, sepenuhnya dimiliki oleh lajureaksi.co.id dan/atau pihak ketiga yang memberikan hak kepada lajureaksi.co.id. Selain penggunaan yang secara tegas diizinkan dan diizinkan sesuai dengan Syarat dan Ketentuan ini, Pengguna tidak memiliki, menerima, atau diberikan hak lain apapun atas Situs ini, termasuk segala data, informasi, dan konten yang ada di dalamnya.</List.Item>
                  <List.Item mb={4}>
                    Dengan mengakses Situs ini atau menggunakan layanan yang tersedia di dalamnya, Pengguna secara tegas menyatakan persetujuan untuk tidak:
                    <List withPadding my={4}>
                      <List.Item mb={4}>menggunakan Laju Reaksi dalam kondisi atau cara apapun yang dapat merusak, melumpuhkan, membebani, atau mengganggu server atau jaringan Laju Reaksi. Kamu juga tidak diperbolehkan untuk mengakses layanan, akun pengguna, sistem komputer atau jaringan secara tidak sah, dengan cara hacking, password mining, atau cara tidak sah lainnya.</List.Item>
                      <List.Item mb={4}>mengunduh, menyimpan, membuat salinan, membagikan, mengunggah ulang, maupun menyebarluaskan dengan cara apapun juga dan media apapun juga isi dari situs web (dengan cara memfoto atau merekam sebagian dari konten edukasi yang ada dan membagikannya melalui media sosial) baik tanpa atau dengan mendapatkan keuntungan komersial;</List.Item>
                      <List.Item mb={4}>menyebabkan Hak Akses dapat digunakan oleh pihak lain selain dari Kamu, baik dengan cara dipinjamkan, dibagikan, disewakan, dipindahtangankan, atau dengan cara apapun lainnya;</List.Item>
                      <List.Item mb={4}>melakukan reproduksi atau replikasi dari situs web, termasuk sebagian atau seluruh kontennya, produk, atau layanan, tidak diizinkan.</List.Item>
                      <List.Item mb={4}>menyebabkan, baik dengan sengaja maupun tidak sengaja, agar seseorang dapat mengakses dan menggunakan situs web menggunakan informasi pendaftaran anda tanpa izin dari kami, merupakan tindakan yang melanggar aturan dan tidak diizinkan.</List.Item>
                    </List>
                  </List.Item>
                </List>
              </Box>
              <Box mb={20}>
                <Text className={classes.titleTerm} mb={6}>Ketentuan Layanan</Text>
                <List type='ordered'>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionTerm} mb={6} fw='bold'>Melakukan Pemesanan</Text>
                      <Text className={classes.captionTerm} mb={4}>Ketika Anda memulai langganan salah satu atau lebih layanan kami, dianggap bahwa Anda menginginkan untuk berlangganan layanan tersebut sesuai dengan syarat dan ketentuan yang berlaku. Semua ini bergantung pada jenis layanan dan konfirmasi harga layanan.</Text>
                      <Text className={classes.captionTerm} mb={4}>Jika pesanan Anda diterima, kami akan memberi tahu Anda melalui email bahwa pesanan Anda telah dimasukkan ke dalam sistem kami dan menunggu pembayaran. Dalam email yang sama, kami juga akan memberikan rincian pembayaran beserta metodenya.</Text>
                      <Text className={classes.captionTerm} mb={4}>Saat melakukan pemesanan, Anda dapat melakukan pembayaran terhadap layanan yang Anda pesan melalui metode pembayaran elektronik yang disediakan oleh pihak ketiga independen ("Penyedia Metode Pembayaran") di Aplikasi, termasuk uang elektronik, rekening bank, atau metode pembayaran lainnya. Pastikan untuk menyediakan semua rincian dengan benar dan akurat, serta memastikan bahwa Anda adalah pengguna resmi dari kartu debit, kartu kredit, atau rekening bank yang digunakan untuk membayar pesanan Anda. Pastikan juga bahwa Anda memiliki dana yang cukup untuk menutupi biaya pesanan Anda. Harga produk dan layanan dapat berfluktuasi, dan semua harga yang diiklankan di situs tunduk pada perubahan tanpa pemberitahuan sebelumnya kepada Anda.</Text>
                    </Box>
                  </List.Item>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionTerm} mb={6} fw='bold'>Pembayaran</Text>
                      <Text className={classes.captionTerm} mb={4}>Setelah Anda melakukan pemesanan, Anda akan menerima email konfirmasi yang menyatakan bahwa pesanan Anda telah diterima, dan bahwa pesanan Anda sedang menunggu pembayaran. Harap dicatat bahwa email konfirmasi ini hanya bersifat konfirmatif dan bukan merupakan finalisasi pesanan atau pembentukan kontrak penjualan.</Text>
                      <Text className={classes.captionTerm} mb={4}>Sebuah kontrak penjualan akan dianggap belum terbentuk hingga kami mengirimkan konfirmasi melalui email yang menyatakan bahwa pembayaran Anda telah diterima dan bahwa barang yang Anda pesan akan segera dikirimkan kepada Anda.</Text>
                    </Box>
                  </List.Item>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionTerm} mb={6} fw='bold'>Harga dan Pengembalian Dana</Text>
                      <Text className={classes.captionTerm} mb={4}>Kami berkomitmen untuk melakukan yang terbaik guna memastikan bahwa semua rincian, deskripsi, ketersediaan, dan harga layanan yang tersedia di situs web kami adalah akurat. Meskipun demikian, kami menyadari bahwa kesalahan mungkin terjadi pada suatu waktu. Apabila terdapat kesalahan teknis yang berada di bawah kendali penuh kami dan menyebabkan kegagalan dalam memberikan layanan, Laju Reaksi dapat membantu memfasilitasi permohonan pengembalian dana.</Text>
                      <Text className={classes.captionTerm} mb={4}>Kesalahan teknis yang dapat menghasilkan pengembalian dana mencakup, namun tidak terbatas pada, masalah server, gangguan sistem, atau permasalahan teknis serupa yang disebabkan oleh Laju Reaksi. Penting untuk dicatat bahwa pengembalian dana tidak akan diproses jika kesalahan atau masalah tersebut disebabkan oleh tindakan atau kelalaian dari pihak pelanggan, seperti pemilihan produk yang salah, kesalahan pengisian data pengguna, atau alamat email yang tidak valid.</Text>
                      <Text className={classes.captionTerm} mb={4}>Adapun skema pengembalian yang dapat kami fasilitasi adalah sebagai berikut:</Text>
                      <List withPadding my={4} className={classes.captionTerm}>
                        <List.Item mb={4}>Pengembalian diajukan dengan memberikan informasi lengkap mengenai data pengguna ke alamat email resmi Laju Reaksi.</List.Item>
                        <List.Item mb={4}>Jumlah pengembalian akan diberikan sesuai dengan selisih kesalahan nominal yang terjadi.</List.Item>
                        <List.Item mb={4}>Periode pengembalian akan dilakukan dalam waktu sekitar 30 hari kerja dan dana akan dikembalikan ke akun bank yang sesuai dengan permohonan.</List.Item>
                        <List.Item mb={4}>Biaya berlangganan, biaya transaksi, dan biaya lainnya yang telah dikirim tidak dapat ditukar, dibatalkan, dikembalikan, diganti, atau ditransfer kepada orang atau pihak lain.</List.Item>
                      </List>
                    </Box>
                  </List.Item>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionTerm} mb={6} fw='bold'>Penggunaan kode promo atau diskon</Text>
                      <Text className={classes.captionTerm} mb={4}>Pelanggan dapat diberikan Kode Promo atau Diskon dari waktu ke waktu. Kode tersebut hanya berlaku untuk pembelian yang dilakukan melalui akun tempat Kode Promo atau Diskon tersebut ditawarkan. Adapun, Kode Promo atau Diskon yang berasal dari satu akun tidak dapat dipindahkan ke akun lainnya.</Text>
                    </Box>
                  </List.Item>
                  <List.Item mb={4}>
                    <Box>
                      <Text className={classes.captionTerm} mb={6} fw='bold'>Hak Akses</Text>
                      <Text className={classes.captionTerm} mb={4}>Dengan membayar layanan dan melakukan langganan, Anda berhak atas Hak Akses untuk Layanan dan Konten eksklusif Laju Reaksi. Hak Akses ini akan berlaku selama Periode Berlangganan dan akan berakhir pada saat Periode Berlangganan tersebut berakhir. Hak Akses yang dimaksud di sini mencakup hak untuk mengakses dan melihat konten, fitur, dan layanan yang tersedia dalam situs web kami.</Text>
                    </Box>
                  </List.Item>
                </List>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </PublicLayout>
    
  )
}

export default TermsCondition
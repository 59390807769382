import React, { useState } from 'react'
import { useViewportSize } from '@mantine/hooks'
import { Box, Button, Grid, ScrollArea, Text, TextInput } from '@mantine/core'
import classes from '../Login/Login.module.css'
import { IconArrowLeft } from '@tabler/icons-react'
import { notificationError, notificationSuccess } from '../../components/ui/Notification'
import { validation } from '../../plugins/validation'
import { resetPasswordRequest } from '../../services/auth'
import { useNavigate } from 'react-router-dom'
import AuthImage from '../../assets/img/RegisterImage.png'

const defaultVal = {
  email: ''
}

const formValidation = {
  email: {
    isError: false,
    message: ''
  }
}

const ResetPassword = () => {
  const navigate = useNavigate()
  const { width, height } = useViewportSize()
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setValidationForm(formValidation)
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    const payload = {
      email: formData.email,
      resetUrl: `lajureaksi.co.id/password-resets/verify`
    }
    try {
      const response = await resetPasswordRequest(payload)
      if (response) {
        console.log(response)
        notificationSuccess('Pengiriman Verifikasi Berhasil', `${response.message}`)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Pengiriman Verifikasi Gagal', 'Silahkan periksa kembali form anda')
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box display='flex'>
        <Box pos='sticky'>
          <Box className={classes.authLayout} style={{ backgroundImage: `url(${AuthImage})`, minHeight: `${height}px`, minWidth: `${width / 2.5}px`}} />
        </Box>
        <ScrollArea h={height} w={width}>
          <Box p={20}>
            <Box mb={60}>
              <Button variant="subtle" leftSection={<IconArrowLeft size={14} />} onClick={() => navigate("/")}>Kembali ke Homepage</Button>
            </Box>
            <Box align='center'>
              <Grid justify='center' align='center'>
                <Grid.Col span={8}>
                  <Box mb={8}>
                    <Text className={classes.titleHeader}>Laju Reaksi</Text>
                  </Box>
                  <Box mb={14}>
                    <Text fz={{ base: 13, lg: 18 }} fw="bold">Permintaan Lupa Password ke Laju Reaksi</Text>
                  </Box>
                  <Box align="left">
                    <form onSubmit={(e) => {handleSubmit(e)}}>
                      <Box mb="xl">
                        <TextInput
                          name="email"
                          placeholder="Masukkan e-mail kamu"
                          label="E-mail"
                          error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                      <Box style={{ margin: '30px 0' }}>
                        <Button fullWidth color="red" loading={loading} type="submit">Kirim Verifikasi</Button>
                      </Box>
                    </form>
                    <Box align="center">
                      <Text fz={13} mb={6}>Sudah Bergabung? <Text fz={13} c="red" onClick={() => navigate("/login")} style={{ cursor:'pointer' }}>Silahkan login</Text></Text>
                    </Box>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </ScrollArea>
      </Box>
    </Box>
  )
}

export default ResetPassword
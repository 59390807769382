import http from "../plugins/axios"

export const getTutorSchedule = async (scheduleId) => {
  const params = {
    schedule: scheduleId
  }
  try {
    const response = await http.get(`/tutors`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}
import React from 'react'
import PublicLayout from '../../../../layouts/PublicLayout'
import classes from './DetailSlug.module.css'
import BgHeaderEvent from '../../../../assets/img/bg-event.webp'
import { Box, Container, Text, Button, Badge } from '@mantine/core'
import { getImageFile } from '../../../../services/fileImage'
import DetailSection from './DetailSection'
import TutorSection from './TutorSection'
import CurriculumSection from './CurriculumSection'
import ScheduleSection from './ScheduleSection'
import { useScrollIntoView } from '@mantine/hooks'

const DetailSlug = ({ detail, loading }) => {
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60
  })

  const handleImage = (filepath) => {
    if (filepath.length > 0) {
      return getImageFile(detail.eventImages[0].filepath)
    } else {
      return BgHeaderEvent
    }
  }
  return (
    <PublicLayout>
      <Box className={classes.headerSection} style={{ backgroundImage: `linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #ece6e6 70%), url(${!loading ? handleImage(detail.eventImages) : ''})`}}>
        <Container fluid className={classes.section}>
          <Box className={classes.innerHeader}>
            <Box className={classes.contentHeader}>
              <Box className={classes.paddingPage}> 
                <Text className={classes.titleHeader} mb={16}>{detail.name}</Text>
                <Badge color="green" variant="filled" mb={10}>
                  {detail.type}
                </Badge>
                <Text className={classes.captionHeader} mb={24}>{detail.description}</Text>
                <Button className={classes.buttonHeader} fz={16} radius="md" onClick={() =>scrollIntoView({alignment: 'center'})}>
                  <Text p={10} fw="bold">Daftar Sekarang</Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.defaultSection}>
        <Container fluid className={classes.section}>
          <Box className={classes.paddingPage}>
            <DetailSection title={detail.name} desc={detail.longDescription} type={detail.type} />
            <TutorSection tutors={detail.tutors} />
            <CurriculumSection curriculums={detail.curriculums} />
            <Box pos='absolute' ref={targetRef} style={{ height: '50vh' }}/>
            <ScheduleSection schedules={detail.eventSchedules}/>
          </Box>
        </Container>
      </Box>
    </PublicLayout>
  )
}

export default DetailSlug
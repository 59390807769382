import http from "../plugins/axios"

export const getPaymentMethod = async () => {
  try {
    const response = await http.get(`/transaction-methods`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailTransactionMethod = async (transactionId) => {
  try {
    const response = await http.get(`/transaction-methods/${transactionId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const confirmPayment = async (payload) => {
  try {
    const response = await http.post(`/transactions`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getTransactionList = async (params) => {
  try {
    const response = await http.get(`/transactions`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailTransactionbyInvoice = async (invoice) => {
  try {
    const response = await http.get(`/transactions/invoice/${invoice}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const validateCodeVoucher = async (codeVoucher, scheduleId) => {
  try {
    const response = await http.get(`/discounts/code/${codeVoucher}/schedule/${scheduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const cancelTransaction = async (transactionId) => {
  try {
    const response = await http.patch(`/transactions/${transactionId}/cancel`)
    return response.data
  } catch(error) {
    throw error
  }
}
import React, { useState, useEffect } from 'react'
import classes from '../../../pages/Blog/Blog.module.css'
import { getBlogs } from  '../../../services/blog'
import { Box, Container, Grid, Skeleton, Text, Flex, Image ,Button } from '@mantine/core'
import dayjs from 'dayjs'
import { getImageFile } from '../../../services/fileImage'
import { useNavigate } from 'react-router-dom' 

const defaultParameter = {
  skip: 0,
  take: 4,
  search: '',
  order: 'desc'
}

const RecentBlogSection = () => {
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(true)
  const [ recentBlog, setRecentBlog ] = useState(null)

  const handleRecentDataBlog = async () => {
    setLoading(true)
    try {
      const response = await getBlogs(defaultParameter)
      setRecentBlog(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleRecentDataBlog()
  }, [])

  const mappingTag = (array) => array.map((val, index) => {
    return (
      <Text key={index} fz="xs" color="red" mr={2}>{val.name}, </Text>
    )
  })

  const loadingHorizontalBlog = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i}>
        <Skeleton height={350} />
        <Flex my={12} style={{ width: '50%' }}>
          <Skeleton height={16} mr={12} />
          <Skeleton height={16} />
        </Flex>
        <Skeleton height={24} mb={12} />
        <Skeleton height={10} mb={6} />
        <Skeleton height={10} mb={12} />
        <Skeleton height={24} width="20%" />
      </Box>
    )
  })

  const loadingVerticalBlog = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i} mb={28}>
        <Grid>
          <Grid.Col span={{ base: 12, lg: 5 }}>
            <Skeleton h={{ base:350, xl: 145, xs: 350 }} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 7 }}>
            <Flex mb={20} style={{ width: '50%' }}>
              <Skeleton height={16} mr={12} />
              <Skeleton height={16} />
            </Flex>
            <Skeleton height={24} mb={12} />
            <Skeleton height={10} mb={6} />
            <Skeleton height={10} mb={12} />
            <Skeleton height={24} width="20%" />
          </Grid.Col>
        </Grid>
      </Box>
    )
  })

  const loadingRecentBlog = () => {
    return (
      <Grid gutter="lg">
        <Grid.Col span={{ base: 12, lg: 6 }}>
          {loading ? loadingHorizontalBlog(1) : ''}
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 6 }}>
          {loading ? loadingVerticalBlog(3) : ''}
        </Grid.Col>
      </Grid>
    )
  }

  // eslint-disable-next-line
  const horizontalBlog = (array) => array.map((val, index) => {
    if (val.isPublished) {
      if (index === 0) {
        return (
          <Box key={index}>
            <img className={classes.posterBlog} radius="lg" fit='cover' src={val.thumbnail !== null ? getImageFile(val.thumbnail) : null} alt={val.title} withPlaceholder />
            <Box mt={14}>
              <Flex mb={2}>
                <Flex>
                  {mappingTag(val.tags)}
                </Flex>
                <Text fz='xs' c="gray.7" ml={4}>{dayjs(val.createdAt).format('DD MMM YYYY')}</Text>
              </Flex>
              <Text className={classes.titleNews} mb={2} lineClamp={1}>{val.title}</Text>
              <Box h={55} mb={12}>
                <Text className={classes.captionNews} lineClamp={2} c="gray.6">{val.caption}</Text>
              </Box>
              <Button size='xs' style={{ backgroundColor: '#043056' }} onClick={() => navigate(`/blog/${val.slug}`)}>Baca Selengkapnya</Button>
            </Box>
          </Box>
        )
      }
    }
  })

  // eslint-disable-next-line
  const verticalBlog = (array) => array.map((val, index) => {
    if (val.isPublished) {
      if (index > 0) {
        return (
          <Box key={index} mb={28}>
            <Grid>
              <Grid.Col span={{ base: 12, lg: 5 }}>
                <Image height={155} radius="lg" fit='cover' src={val.thumbnail !== null ? getImageFile(val.thumbnail) : null} alt={val.title} withPlaceholder />
              </Grid.Col>
              <Grid.Col span={{ base: 12, lg: 7 }}>
                <Flex mb={2}>
                  <Flex>
                    {mappingTag(val.tags)}
                  </Flex>
                  <Text fz='xs' c="gray.7">{dayjs(val.createdAt).format('DD MMM YYYY')}</Text>
                </Flex>
                <Text className={classes.titleNews} mb={2} fw="bold" lineClamp={1}>{val.title}</Text>
                <Box h={55} mb={12}>
                  <Text className={classes.captionNews} lineClamp={2} c="gray.6">{val.caption}</Text>
                </Box>
                <Button size='xs' style={{ backgroundColor: '#043056' }} onClick={() => navigate(`/blog/${val.slug}`)}>Baca Selengkapnya</Button>
              </Grid.Col>
            </Grid>
          </Box>
        )
      }
    }
  })

  const loadDataRecentBlog = (data) => {
    if (data.length > 0) {
      return (
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 12, lg: 6 }}>
            {horizontalBlog(recentBlog)}
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 6 }}>
            {verticalBlog(recentBlog)}
          </Grid.Col>
        </Grid>
      )
    }
  }


  return (
    <Box className={classes.blogSection}>
      <Container fluid className={classes.section}>
        <Box className={classes.paddingPage}>
          <Text className={classes.titleBlog} fw="bold">Recent Blog Post</Text>
          <Box mt={16}>
            {loading ? loadingRecentBlog() : loadDataRecentBlog(recentBlog)}
          </Box>
        </Box>
        
      </Container>
    </Box>
  )
}

export default RecentBlogSection
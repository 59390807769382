import { configureStore } from '@reduxjs/toolkit'
import authData from '../store/authData'
import authMember from '../store/authMember'
import cartData from '../store/cartData'
import memberProgram from '../store/memberProgram'

export const store = configureStore({
  reducer: {
    auth: authData,
    member: authMember,
    checkout: cartData,
    program: memberProgram
  },
})
